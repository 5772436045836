import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'articleSearchPipe'
})
export class ArticleSearchPipe implements PipeTransform {

  transform(articles: any[], searchText: string): any[] {
    if (!articles || !searchText) {
      return articles;
    }

    searchText = this.replaceSerbianLatin(searchText);

    return articles.filter(article => {
      const name = this.replaceSerbianLatin(article.name).toLowerCase();
      const code = this.replaceSerbianLatin(article.code).toLowerCase();
      return name.includes(searchText.toLowerCase()) || code.includes(searchText.toLowerCase());
    });
  }

  private replaceSerbianLatin(text: string): string {
    const replacements: Replacements = { 'č': 'c', 'ć': 'c', 'đ': 'dj', 'š': 's', 'ž': 'z' };
    return text.replace(/[čćđšž]/gi, match => replacements[match.toLowerCase()]);
  }

}

interface Replacements {
  [key: string]: string;
}