import { FirebaseDate } from "../util/firebaseDate";

export interface IOrganization {
    address: IOrganizationAddress;
    createdByUserId: string;
    email: string;
    id: string;
    isRegisteredCustomer: boolean;
    isRegisteredSupplier: boolean;
    name: string;
    logoUrl: string;
    phoneNumber: IOrganizationPhoneNumber;
    registrationDate: Date;
    registrationId: string;
    taxId: string;
    countryCode: string;
}

export interface IOrganizationDate {
    nanoseconds: number;
    seconds: number;
}

export interface IOrganizationAddress {
    city: string;
    postCode: string;
    streetName: string;
    streetNumber: string;

    // constructor(obj?:any) {
    //     this.city = obj && obj.city || "";
    //     this.postCode = obj && obj.postCode || "";
    //     this.streetName = obj && obj.streetName || "";
    //     this.streetNumber = obj && obj.streetNumber || "";
    // }
}

export interface IOrganizationPhoneNumber {
    landline: string;

    // constructor(obj?:any) {
    //     this.landline = obj && obj.landline || '';
    // }
}

export class Organization implements IOrganization {

    address: OrganizationAddress;
    createdByUserId: string;
    email: string;
    id: string;
    isRegisteredCustomer: boolean;
    isRegisteredSupplier: boolean;
    name: string;
    logoUrl: string;
    phoneNumber: OrganizationPhoneNumber;
    registrationDate: Date;
    registrationId: string;
    taxId: string;
    countryCode: string;

    constructor(obj?: any) {
        // this.address = obj && new OrganizationAddress(obj.address) || new OrganizationAddress();
        this.address = obj && new OrganizationAddress(obj.address) || new OrganizationAddress();
        this.createdByUserId = obj && obj.createdByUserId || "";
        this.email = obj && obj.email || "";
        this.id = obj && obj.id || "";
        this.isRegisteredCustomer = obj && obj.isRegisteredCustomer || false;
        this.isRegisteredSupplier = obj && obj.isRegisteredSupplier || false;
        this.name = obj && obj.name || "";
        this.logoUrl = obj && obj.logoUrl || "";
        this.phoneNumber = obj && new OrganizationPhoneNumber(obj.phoneNumber) || new OrganizationPhoneNumber();
        this.registrationDate = obj && obj.registrationDate && (typeof obj.registrationDate.toDate == 'function' &&
            obj.registrationDate.toDate() || obj.registrationDate) || new Date();
        this.registrationId = obj && obj.registrationId || "";
        this.taxId = obj && obj.taxId || "";
        this.countryCode = obj && obj.countryCode || "";
    }

    toFirestore() {
        return {
            address: this.address.toFirestore(),
            createdByUserId: this.createdByUserId,
            email: this.email,
            id: this.id,
            isRegisteredCustomer: this.isRegisteredCustomer,
            isRegisteredSupplier: this.isRegisteredSupplier,
            name: this.name,
            logoUrl: this.logoUrl,
            phoneNumber: this.phoneNumber.toFirestore(),
            registrationDate: this.registrationDate,
            registrationId: this.registrationId,
            taxId: this.taxId,
            countryCode: this.countryCode
        }
    }

}

export class OrganizationDate implements IOrganizationDate {
    nanoseconds: number;
    seconds: number;

    constructor(obj?: any) {
        this.nanoseconds = obj && obj.nanoseconds || 0;
        this.seconds = obj && obj.seconds || 0;
    }

    toFirestore(): any {
        return new Date(this.seconds);
    }

}



export class OrganizationAddress implements IOrganizationAddress {
    city: string;
    postCode: string;
    streetName: string;
    streetNumber: string;

    constructor(obj?: any) {
        this.city = obj && obj.city || "";
        this.postCode = obj && obj.postCode || "";
        this.streetName = obj && obj.streetName || "";
        this.streetNumber = obj && obj.streetNumber || "";
    }

    toFirestore(): any {
        return {
            city: this.city,
            postCode: this.postCode,
            streetName: this.streetName,
            streetNumber: this.streetNumber
        }
    }
}

export class OrganizationPhoneNumber implements IOrganizationPhoneNumber {
    landline: string;

    constructor(obj?: any) {
        this.landline = obj && obj.landline || '';
    }

    toFirestore(): any {
        return {
            landline: this.landline
        }
    }
}