import { JoinRequest } from "src/app/core/models/organization/joinRequest"
import { IOrganization } from "src/app/core/models/organization/organization"
import { UserFS } from "src/app/core/models/user/user"


export namespace UserOrganizationsPaylaods {
    export type CreateJoinRequest = Partial<JoinRequest>
    export type CreateOrganization = Partial<IOrganization>
    export type CancelJoinRequest = string
    export type SwitchOrganization = string;
}


export namespace UserOrganizationsActions {

    export class GetOrganizations {
        public static readonly type = '[UserOrganizationsActions] GetOrganizations'
    }

    export class UpdateOrganizationsBucket {
        public static readonly type = '[UserOrganizationsActions] UpdateOrganizationsBucket'
        constructor(public orgId: string) { }
    }

    export class SwitchOrganization {
        public static readonly type = '[UserOrganizationsActions] SwitchOrganization'
        constructor(public organizationId: UserOrganizationsPaylaods.SwitchOrganization) { }
    }

    // export class GetJoinRequests {
    //     public static readonly type = '[UserOrganizationsActions] GetJoinRequests'
    // }

    // export class CreateJoinRequest {
    //     public static readonly type = '[UserOrganizationsActions] CreateJoinRequest'
    //     constructor(public payload: UserOrganizationsPaylaods.CreateJoinRequest) { }
    // }

    // export class CreateOrganization {
    //     public static readonly type = '[UserOrganizationsActions] CreateOrganization'
    //     constructor(public payload: UserOrganizationsPaylaods.CreateOrganization) { }
    // }

    export class CancelJoinRequest {
        public static readonly type = '[UserOrganizationsActions] CancelJoinRequest'
        constructor(public payload: UserOrganizationsPaylaods.CancelJoinRequest) { }
    }
}