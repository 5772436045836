import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(value: string): any {
    switch (value) {
      case "PENDING":
        return "U obradi"
      case "MODIFIED":
        return "Predložene izmene"
      case "ACCEPTED":
        return "Prihvaćeno"
      case "REJECTED":
        return "Odbijeno"
      case "CANCELLED":
        return "Stornirano"
      case "INITIALIZED":
        return "Započeto"
      case "SUBMITTED":
        return "Podneto"
      case "APPROVED":
        return "Odobreno"
      case "SENT_TO_SUPPLIER":
        return "Poslato dobavljaču"
      case "RECEIVED":
        return "Primljeno"
      case "NOT_DELIVERED":
        return "Nije isporučeno"
      case "PREPARED":
        return "Pripremljeno"
      case "IN_PREPARATION":
        return "U pripremi"
      case "SHIPPED":
        return "Poslato"
      case "RECEIVED_BY_CUSTOMER":
        return "Isporučeno kupcu"
      case "INVOICED":
        return "Fakturisano"
    }
  }

}
