export interface IArticleReservation {
  code: string;
  id: string
  invoiced: number;
  reserved: number;
}

export class ArticleReservation implements IArticleReservation {

  code: string;
  id: string;
  invoiced: number;
  reserved: number;

  constructor(obj?: any) {
    this.code = obj && obj.code || ""
    this.id = obj && obj.id || ""
    this.invoiced = obj && obj.invoiced || null
    if(obj.invoiced === 0) {
      this.invoiced = 0
    }
    this.reserved = obj && obj.reserved || null
    if(obj.reserved === 0) {
      this.reserved = 0
    }
  }

  toFirestore() {
    return {
      code: this.code,
      id: this.id,
      invoiced: this.invoiced,
      reserved: this.reserved,
    }
  }
}
