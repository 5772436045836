import { IArticle } from "src/app/core/models/article/article";
import { Note } from "src/app/core/models/order/note";
import { IOrderArticle } from "src/app/core/models/order/orderArticle";
import { IOutboundOrder, OutboundOrder } from "src/app/core/models/order/outboundOrder";


export namespace SupplierNewOrderPayloads {
    
    export type AddArticles = Partial<IOrderArticle>[];
    export type UpdateValues = Partial<IOutboundOrder>;
    export type LoadExistingOrder = {orderId: string};
    export type OrderStatus = Partial<IOutboundOrder> | any;
    export type LoadCustomerData = Partial<{ supplierId: string}>;
    export type Create = string;
    export type AddOrderNote = Partial<{note: Note}>;
    export type RemoveOrderNote = number;
    export type SupplierId = string
    

}

export namespace SupplierNewOrderActions {
    export class AddArticles {
        public static readonly type = '[SupplierNewOrder] AddArticles';
        constructor(public payload: SupplierNewOrderPayloads.AddArticles) { };
    }

    export class RemoveOrderNotes {
        public static readonly type = '[InboundOrder] RemoveOrderNote'
        constructor(public payload: SupplierNewOrderPayloads.RemoveOrderNote){}
    }
    export class AddOrderNote {
        public static readonly type = '[SupplierNewOrder] AddOrderNote'
        constructor(public payload: SupplierNewOrderPayloads.AddOrderNote){}
    }

    export class UpdateValues {
        public static readonly type = '[SupplierNewOrder] UpdateValues';
        constructor(public payload: SupplierNewOrderPayloads.UpdateValues) { };
    }
    export class LoadExistingOrder {
        public static readonly type = '[InboundOrder] LoadPrevoiusOrder'
        constructor(public payload: SupplierNewOrderPayloads.LoadExistingOrder){}
    }
    export class OrderStatus {
        public static readonly type = '[SupplierNewOrder] OrderStatus';
        constructor(public payload: SupplierNewOrderPayloads.OrderStatus) { };
    }

    export class Create {
        public static readonly type = '[SupplierNewOrder] CreateOrder';
        constructor(public payload: SupplierNewOrderPayloads.SupplierId) {};
    }

    export class SaveOrder {
        public static readonly type = '[SupplierNewOrder] SaveOrder'
    }

    export class LoadCustomerData {
        public static readonly type = '[SupplierNewOrder] LoadCustomerData'
        constructor(public payload: SupplierNewOrderPayloads.LoadCustomerData){}
    }

    export class ClearState {
        public static readonly type = '[SupplierNewOrder] ClearState'
    }
}