import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { TypesenseService } from "src/app/core/services/typesense.service";
import { environment } from "src/environments/environment";
import { SearchParams, SearchResponse } from "typesense/lib/Typesense/Documents";

export class CustomerTypesenseResponse {
    found: number;
    out_of: number;
    currentPage: number;
    totalPages: number;
    results: CustomerTS[]

    constructor(found: number, currentPage: number, out_of: number, itemPerPage: number, customersTS: CustomerTS[]) {
        this.out_of = out_of;
        this.found = found;
        this.currentPage = currentPage;
        this.results = customersTS
        this.totalPages = Math.ceil(found / itemPerPage)
    }
}

export class CustomerTS {
    id: string;
    fullName: string;
    externalReferenceCode: string;
    isInactive: boolean;


    constructor(obj?: any) {
        this.id = obj && obj.id || ""
        this.fullName = obj && obj.fullName || "";
        this.externalReferenceCode = obj && obj.externalReferenceCode || "";
        this.isInactive = obj && obj.isInactive || false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomersTypesense {

    protected path = `${environment.production ? 'prod' : 'stage'}_organization_customers`

    constructor(private typesense: TypesenseService) { }

    search(search: SearchParams): Observable<CustomerTypesenseResponse> {
        const searchSubject = new Subject<CustomerTypesenseResponse>()
        this.typesense.orgClient.collections(this.path).documents().search(search).then((response: SearchResponse<{}>) => {
            if (!response.hits) {
                searchSubject.next(new CustomerTypesenseResponse(0, 0, 0, 0, []))
                return
            }

            var searchHits: CustomerTS[] = [];
            response.hits.map((elem: any) => searchHits.push(new CustomerTS(elem.document)))
            searchSubject.next(new CustomerTypesenseResponse(response.found, response.page, response.out_of, search.per_page ?? 20, searchHits));

            searchSubject.complete()
        }).catch((err: any) => searchSubject.error(err))
        return searchSubject.asObservable();
    }
}
