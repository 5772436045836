<!-- <h2 mat-dialog-title class="text-align-left">Info</h2>
<h3 mat-dialog-content class="main-text-center">{{data.text}}</h3>
<mat-dialog-actions class="actions-container ">
    <button mat-raised-button class="button" cdkFocusInitial (click)="close()">OK</button>
</mat-dialog-actions> -->

<h1 mat-dialog-title>Info</h1>
<div mat-dialog-content>{{data.text}}</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>OK</button>
</div>