import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { OrganizationConfig } from '../models/organization/config';
import { OrganizationConfigService } from '../services/organization-config.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationConfigResolver implements Resolve<OrganizationConfig> {

  constructor(private configService: OrganizationConfigService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrganizationConfig> {

    return this.configService.configAsObservable().pipe(
      skipWhile((config: OrganizationConfig) => {
        // console.log(config)
        if (!config.activeModules) return true; //if this is active the app doesn't start
        if (!config.integration) return true;
        if (!config.orderStatusCodes) return true;
        if (!config.splitInboundOrderArticles) return true;
        if (!config.warehouseOrdersManagement) return true;
        return false;
      }), 
      take(1), 
      map((config: OrganizationConfig) => {
        // console.log('Org config resolved')
        return config;
      }))

  }

  
}
