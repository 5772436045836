import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserService } from 'src/app/auth/services/user.service';
import { OrganizationConfig, OrganizationConfigs } from '../models/organization/config';
import { UserFS } from '../models/user/user';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationConfigService implements OnDestroy {

  configSubject = new BehaviorSubject<OrganizationConfig>(new OrganizationConfig());
  OrganizationConfigIntegration$ = this.configSubject.asObservable()

  activeModulesSub!: Subscription | null;
  integrationSub!: Subscription | null;
  orderLabelsSub!: Subscription | null;
  orderStatusCodesSub!: Subscription | null;
  splitInboundOrderArticlesSub!: Subscription | null;
  warehouseOrdersManagementSub!: Subscription | null;

  constructor(
    private userService: UserService,
    private db: AngularFirestore,
    private organizationService: OrganizationService
  ) {
    this.userService.orgUserAsObservable().subscribe((user: any) => {
      if (user && user.orgId) {
        const orgId = this.organizationService.getCurrentOrganizationId() || user.orgId
        this.fetchActiveModules(orgId)
        this.fetchIntegration(orgId)
        this.fetchOrderLabels(orgId)
        this.fetchOrderStatusCodes(orgId)
        this.fetchSplitInboundOrderArticles(orgId);
        this.fetchWarehouseOrdersManagement(orgId)
      } else {
        this.unsubscribeAll();
        this.configSubject.next(new OrganizationConfig())
      }
    })
  }

  getOrganizationConfig(): OrganizationConfig {
    return this.configSubject.value;
  }

  configAsObservable(): Observable<OrganizationConfig> {
    return this.OrganizationConfigIntegration$;
  }

  private unsubscribeAll(): void {
    if (this.activeModulesSub) {
      this.activeModulesSub.unsubscribe();
      this.activeModulesSub = null; // this.activeModulesSub.next(null) ???
    }

    if (this.integrationSub) {
      this.integrationSub.unsubscribe();
      this.integrationSub = null;
    }

    if (this.orderLabelsSub) {
      this.orderLabelsSub.unsubscribe()
      this.orderLabelsSub = null;
    }

    if (this.orderStatusCodesSub) {
      this.orderStatusCodesSub.unsubscribe()
      this.orderStatusCodesSub = null;
    }

    if (this.splitInboundOrderArticlesSub) {
      this.splitInboundOrderArticlesSub.unsubscribe()
      this.splitInboundOrderArticlesSub = null;
    }

    if (this.warehouseOrdersManagementSub) {
      this.warehouseOrdersManagementSub.unsubscribe()
      this.warehouseOrdersManagementSub = null
    }
  }

  private fetchActiveModules(orgId: string): void {
    this.activeModulesSub = this.db.collection('organizations')
      .doc(orgId)
      .collection('config')
      .doc('activeModules')
      .valueChanges()
      .subscribe((data: any) => {
        const activeModules = new OrganizationConfigs.ActiveModules(data)
        const newOrgValue = new OrganizationConfig({ ...this.configSubject.value, activeModules })
        this.configSubject.next(newOrgValue)
      })
  }

  private fetchIntegration(orgId: string): void {
    this.integrationSub = this.db.collection('organizations')
      .doc(orgId)
      .collection('config')
      .doc('integration')
      .valueChanges()
      .subscribe((data: any) => {
        const integration = new OrganizationConfigs.Integration(data)
        const newOrgValue = new OrganizationConfig({ ...this.configSubject.value, integration })
        this.configSubject.next(newOrgValue)
      })
  }

  private fetchOrderLabels(orgId: string): void {
    this.orderLabelsSub = this.db.collection('organizations')
      .doc(orgId)
      .collection('config')
      .doc('orderLabels')
      .valueChanges()
      .subscribe((data: any) => {
        const orderLabels = new OrganizationConfigs.OrderLabels(data)
        const newOrgValue = new OrganizationConfig({ ...this.configSubject.value, orderLabels })
        this.configSubject.next(newOrgValue)
      })
  }


  private fetchOrderStatusCodes(orgId: string): void {
    this.orderStatusCodesSub = this.db.collection('organizations')
      .doc(orgId)
      .collection('config')
      .doc('orderStatusCodes')
      .valueChanges()
      .subscribe((data: any) => {
        const orderStatusCodes = new OrganizationConfigs.OrderStatusCodes(data)
        const newOrgValue = new OrganizationConfig({ ...this.configSubject.value, orderStatusCodes })
        this.configSubject.next(newOrgValue)
      })
  }

  private fetchSplitInboundOrderArticles(orgId: string): void {
    this.splitInboundOrderArticlesSub = this.db.collection('organizations')
      .doc(orgId)
      .collection('config')
      .doc('splitInboundOrderArticles')
      .valueChanges()
      .subscribe((data: any) => {
        const splitInboundOrderArticles = new OrganizationConfigs.SplitInboundOrderArticles(data)
        const newOrgValue = new OrganizationConfig({ ...this.configSubject.value, splitInboundOrderArticles })
        this.configSubject.next(newOrgValue)
      })
  }

  private fetchWarehouseOrdersManagement(orgId: string): void {
    this.warehouseOrdersManagementSub = this.db.collection('organizations')
      .doc(orgId)
      .collection('config')
      .doc('warehouseOrdersManagement')
      .valueChanges()
      .subscribe((data: any) => {
        const warehouseOrdersManagement = new OrganizationConfigs.WarehouseOrdersManagement(data)
        const newOrgValue = new OrganizationConfig({ ...this.configSubject.value, warehouseOrdersManagement })
        this.configSubject.next(newOrgValue)
      })
  }

  ngOnDestroy(): void {
    this.unsubscribeAll()
  }
}
