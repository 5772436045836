import { StatusCode } from './statusCode'

export interface IOrderStatus {
    code: StatusCode;
    message: string;
    triggeredByUserId: string;
    triggeredByUserName: string;
    triggeredAtDate: Date;
}

export class OrderStatus {

    code: StatusCode;
    message: string;
    triggeredByUserId: string;
    triggeredByUserName: string;
    triggeredAtDate: Date;

    constructor(obj?: any) {
        this.code = obj && obj.code || null;
        this.message = obj && obj.message || "";
        this.triggeredByUserId = obj && obj.triggeredByUserId || "";
        this.triggeredByUserName = obj && obj.triggeredByUserName || "";
        this.triggeredAtDate = obj && obj.triggeredAtDate && (typeof obj.triggeredAtDate.toDate == 'function' &&
            obj.triggeredAtDate.toDate() || obj.triggeredAtDate) || null;
    }

    toFirestore(): any {
        return {
            code: this.code,
            message: this.message,
            triggeredByUserId: this.triggeredByUserId,
            triggeredAtDate: this.triggeredAtDate
        }
    }

    fromFirestore(snapshot: any, options: any): any {
        const data = snapshot.data(options);
        return new OrderStatus(data);
    }
}