import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './core/components/navigation/navigation.component';
import { ViewOutboundOrderComponent } from './unregistered-organization/view-outbound-order/view-outbound-order.component';
import { HomeComponent } from './core/components/home/home.component';
import { RolesGuard } from './auth/guards/roles.guard';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { OrganizationResolver } from './core/resolvers/organization.resolver';
import { OrgUserResolver } from './core/resolvers/org-user.resolver';
import { CountryCodeResolver } from './core/resolvers/country-code.resolver';
import { OrganizationConfigResolver } from './core/resolvers/organization-config.resolver';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { AnonymousGuard } from './auth/guards/anonymous-guard.guard';
import { PhoneLoginAndVerificationComponent } from './auth/components/phone-login-and-verification/phone-login-and-verification.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: 'app',
    pathMatch: 'prefix',
    canActivate: [AuthGuard],
    resolve: {
      organziation: OrganizationResolver,
      // orgUser: OrgUserResolver,
      countryCodeResolved: CountryCodeResolver,
      orgConfig: OrganizationConfigResolver
    },
    component: NavigationComponent,
    children: [
      {
        path: 'warehouse-orders',
        canActivate: [RolesGuard],
        loadChildren: () => import('./warehouse-orders/warehouse-orders.module').then(m => m.WarehouseOrdersModule),
        data: {
          roles: ['ADMIN', 'WAREHOUSEMANAGER', 'ACCOUNTANT', 'WAREHOUSECLERK']
        },
      },
      {
        path: 'users-management',
        canActivate: [RolesGuard],
        loadChildren: () => import('./settings/settings.module').then(m => m.UsersManagementModule),
        data: {
          roles: ['ADMIN']
        }
      },
      {
        path: 'salesrep',
        canActivate: [RolesGuard],
        loadChildren: () => import('./salesrep/salesrep.module').then(m => m.SalesrepModule),
        data: {
          roles: ['ADMIN', 'SALESREP']
        }
      },
      {
        path: 'procurement',
        canActivate: [RolesGuard],
        loadChildren: () => import('./procurement/procurement.module').then(m => m.ProcurementModule),
        data: {
          roles: ['ADMIN', 'PROCUREMENTMANAGER']
        },
      },
      {
        path: 'expenses',
        loadChildren: () => import('./expenses/expenses.module').then(m => m.ExpensesModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  },
  { path: 'login', pathMatch: 'full', component: PhoneLoginAndVerificationComponent, canActivate: [AnonymousGuard] },
  {
    path: 'account',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),

  },
  {
    path: 'outboundOrder/:emailId',
    loadChildren: () => import('./unregistered-organization/unregistered-organization.module').then(m => m.UnregisteredOrganizationModule),
    component: ViewOutboundOrderComponent,
  },
  { path: '', redirectTo: '/app/home', pathMatch: "full" },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })], // enable tracing for debug, will print all events to console
  exports: [RouterModule]
})
export class AppRoutingModule { }
