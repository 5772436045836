import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControlStatus, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { CustomerLocation } from '../../models/customer/customerLocation';

@Component({
  selector: 'app-location-control',
  templateUrl: './location-control.component.html',
  styleUrls: ['./location-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: LocationControlComponent
    }
  ]
})
export class LocationControlComponent implements ControlValueAccessor, Validator {

  locations: FormArray;

  onChange = (locations: CustomerLocation[]) => {}
  onTouched = () => {};

  get locationsControls() {
    return this.locations.controls as FormGroup[];
  }




  constructor(
    private fb: FormBuilder
  ) { 
    this.locations = fb.array([this.createLocation()])
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.locations.valid) return null;

    let errors: ValidationErrors = {};

    for (let i in this.locationsControls) {
      
    }

    return errors;

  }

  

  registerOnValidatorChange?(fn: () => void): void {
    throw new Error('Method not implemented.');
  }

  writeValue(locations: CustomerLocation[]): void {
    this.locations.setValue(locations, {emitEvent: false})
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.locations.disable();
    }
    else {
      this.locations.enable();
    }
  }

  ngOnInit(): void {
    this.locations
  }


  createLocation() {
    this.fb.group({}).valueChanges.subscribe(() => {
      this.onChange(this.locations.value)
    })
    return this.fb.group({
      locationName: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required]
    })
  }


  addLocation() {
    this.locations.push(this.createLocation())
    this.onChange(this.locations.value)
  }


  removeLocation(i: number){
    this.locations.removeAt(i)
    this.onChange(this.locations.value)
  }

}
