import { Customer } from "src/app/core/models/customer/customer";
import { SearchParams } from "typesense/lib/Typesense/Documents";

export namespace CustomersPayloads {
  export type ConnectCustomer = string;
  export type SearchCustomers = SearchParams;
  export type CreateCustomer = Customer
}

export namespace CustomersActions {

  export class LoadCustomerById {
    public static readonly type = '[CustomersActions] loadCustomerById'
    //constructor(public payload: CustomersPayloads.ConnectCustomer) { }
    constructor(public customerId: string) { }
  }

  export class UpdateLoadedCustomer {
    public static readonly type = '[CustomersActions] UpdateLoadedCustomer'
    constructor(public customerObject: Customer) { }
  }

  export class SelectCustomer {
    public static readonly type = '[CustomersActions] SelectCustomer'
    constructor(public customerId: string) { }
  }

  export class LoadCustomers {
    public static readonly type = '[CustomersActions] LoadCustomers'
    constructor(public payload: CustomersPayloads.SearchCustomers) { }
  }

  export class ResetSearchResult {
    public static readonly type = '[CustomersActions] ResetSearchResult'
  }

  export class Create {
    public static readonly type = '[CustomersActions] CreateCustomer'
    constructor(public payload: CustomersPayloads.CreateCustomer) { }
  }
}
