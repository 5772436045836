import { InboundOrderSupplierSalesRep } from "./inboundOrderSupplierSalesRep";

export interface IOrderSupplier {
    id: string;
    name: string;
    taxId: string;
    deliveryVehicle: string;
    deliveryRegion: string;
    fulfilmentOrg: string;
    salesRep: InboundOrderSupplierSalesRep // Rename to OrderSupplierSalesRep
    registered: boolean;
}


export class OrderSupplier implements IOrderSupplier {
    id: string;
    name: string;
    taxId: string;
    deliveryVehicle: string;
    deliveryRegion: string;
    fulfilmentOrg: string;
    salesRep: InboundOrderSupplierSalesRep;
    registered: boolean;
    email: string;
    defaultFulfilmentWarehouseCode: string;

    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.taxId = obj && obj.taxId || "";
        this.deliveryVehicle = obj && obj.deliveryVehicle || "";
        this.deliveryRegion = obj && obj.deliveryRegion || "";
        this.fulfilmentOrg = obj && obj.fulfilmentOrg || "";
        this.salesRep = obj && new InboundOrderSupplierSalesRep(obj.salesRep) || new InboundOrderSupplierSalesRep();
        this.registered = obj && obj.registered || false;
        this.email = obj && obj.email || "";
        this.defaultFulfilmentWarehouseCode = obj && obj.defaultFulfilmentWarehouseCode || "";
    }

    toFirestore(): any {
        return {
            id: this.id,
            name: this.name,
            taxId: this.taxId,
            deliveryVehicle: this.deliveryVehicle,
            deliveryRegion: this.deliveryRegion,
            fulfilmentOrg: this.fulfilmentOrg,
            salesRep: this.salesRep.toFirestore(),
            registered: this.registered,
            email: this.email,
            defaultFulfilmentWarehouseCode: this.defaultFulfilmentWarehouseCode
        }
    }
}