import { Component, Input, OnInit } from '@angular/core';
import { Note } from 'src/app/core/models/order/note';

@Component({
  selector: 'app-note-overview',
  templateUrl: './note-overview.component.html',
  styleUrls: ['./note-overview.component.sass']
})
export class NoteOverviewComponent implements OnInit {

  @Input() notes!: Note[]

  constructor() { }

  ngOnInit(): void {
  }

}
