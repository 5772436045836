import { Article } from "src/app/core/models/article/article";
import { SearchParams } from "typesense/lib/Typesense/Documents";

export namespace ArticlesPayloads {
  export type ConnectArticle = string;
  export type SearchArticles = SearchParams;
}

export namespace ArticlesActions {

  export class ConnectArticle {
    public static readonly type = '[ArticlesActions] ConnectArticle'
    //constructor(public payload: ArticlesPayloads.ConnectArticle) { }
    constructor(public articleId: string) { }
  }

  export class UpdateStateArticle {
    public static readonly type = '[ArticlesActions] UpdateStateArticles'
    constructor(public article: Article) { }
  }

  export class ConnectAllArticles {
    public static readonly type = '[ArticlesActions] ConnectAllArticles'
    //constructor(public payload: ArticlesPayloads.ConnectArticle) { }
    constructor(public articleIds: string[]) { }
  }

  export class ConnectReservations {
    public static readonly type = '[ArticlesActions] ConnectReservations'
  }

  export class SearchArticles {
    public static readonly type = '[ArticlesActions] SearchArticles'
    constructor(public payload: ArticlesPayloads.SearchArticles) { }
  }

  // export class LoadArticlesFromTypesense {
  //   public static readonly type = '[ArticlesActions] LoadArticlesFromTypesense'
  //   constructor(public payload: ArticlesPayloads.SearchArticles) { }
  // }

  export class LoadProcurementMasterArticlesFromTypesense {
    public static readonly type = '[ArticlesActions] LoadProcurementMasterArticlesFromTypesense'
    constructor(public searchparam: SearchParams) { }
  }
}
