import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OutboundOrder } from 'src/app/core/models/order/outboundOrder';

@Injectable({
  providedIn: 'root'
})
export class ExternalOutboundOrderService {

  constructor(
    private db: AngularFirestore
  ) { }

  getOrderRoot(emailId: string): Observable<OutboundOrder> {
    return this.db.collection('outbound_orders', (ref) => ref.where('emailId', '==', emailId))
    .valueChanges()
    .pipe(map((snapshot: any) => {
      return new OutboundOrder(snapshot[0])
    }))
   }
}

