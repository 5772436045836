import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumFiltered'
})
export class SumFilteredPipe implements PipeTransform {

  transform(items: any[], attr: string, fltr?: string): any {
    if(fltr){
      return items.filter(item => item.label === fltr).reduce((a, b) => a + b[attr], 0);
    } else {
      return items.reduce((a,b) => a + b[attr], 0)
    }
    
  }

}
