import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import packageInfo from '../../../../package.json';


@Injectable({
  providedIn: 'root'
})
export class VersionService {

  static instance: VersionService;

  constructor() {
    VersionService.instance = this;
  }

  getVersion(): string {
    return packageInfo.version;
  }

  // constructor(private http: HttpClient) {
  //   this.getVersion();
  // }

  // getVersion(): void {
  //   this.http.get("assets/version.json").subscribe((versionData: any) => {

  //     appVersion = versionData && versionData.version || "-1";

  //     console.log("App version:", appVersion)
  //   })
  // }


}
