export interface ICustomerPaymentInformation {
    discountPrimary: number;
    discountSecondary: number;
    externalReferenceCode: string;
    group: string;
    paymentTerms: number;
}

export class CustomerPaymentInformation {
    discountPrimary: number;
    discountSecondary: number;
    externalReferenceCode: string;
    group: string;
    paymentTerms: number;

    constructor(obj?: any) {
        this.discountPrimary = obj && obj.discountPrimary || 0;
        this.discountSecondary = obj && obj.discountSecondary || 0;
        this.externalReferenceCode = obj && obj.externalReferenceCode || "";
        this.group = obj && obj.group || "";
        this.paymentTerms = obj && obj.paymentTerms || 0;
    }

    toFirestore(): any {
        return {
            discountPrimary: this.discountPrimary,
            discountSecondary: this.discountSecondary,
            externalReferenceCode: this.externalReferenceCode,
            group: this.group,
            paymentTerms: this.paymentTerms
        };
    }
    fromFirestore(snapshot: any, options: any) {
        const data = snapshot.data(options);
        return new CustomerPaymentInformation(data);
    }
}