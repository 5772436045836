export interface ICustomerContactPersonRole {
    id: string;
    name: string;
}

export class CustomerContactPersonRole{
    id: string;
    name: string;

    constructor(obj?: any){
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
    }

    toFirestore(): any {
        return {
            id: this.id,
            name: this.name,
            };
    }

    fromFirestore(snapshot: any, options: any){
        const data = snapshot.data(options);
        return new CustomerContactPersonRole(data);
    }
}
