import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(value: string): any {
    switch (value) {
      case "MASTERADMIN":
        return "Vlasnik Naloga"
      case "ADMIN":
        return "Administrator"
      case "SALESREP":
        return "Komercijalista"
      case "WAREHOUSEMANAGER":
        return "Magacioner"
      case "WAREHOUSECLERK":
        return "Pomoćnik Magacionera"
      case "PROCUREMENTMANAGER":
        return "Menadžer Nabavke"
    }
  }

}
