import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormControl } from '@angular/forms';
import { CountryCallingCode, CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { CountryCodeService } from '../../services/country-code.service';



@Component({
  selector: 'app-country-code-selector',
  templateUrl: './country-code-selector.component.html',
  styleUrls: ['./country-code-selector.component.sass']
})
export class CountryCodeSelectorComponent implements OnInit {

  codes: { countryCode: CountryCode, countryCallingCode: CountryCallingCode }[];
  selectedCode: CountryCode = 'RS'

  @Output()
  codeSelected: EventEmitter<CountryCode> = new EventEmitter();

  @Input()
  displayCallingCode: boolean = true;

  @Input()
  saveCodeToLocalStorage: boolean = true;

  @Input()
  disableControl: boolean = false;

  codeFormControl: FormControl;
  constructor(private countryCodeService: CountryCodeService) {
    const initialCodeValue = countryCodeService.getCodeFromLocalStorage();
    this.codeFormControl = new FormControl(initialCodeValue)

    this.codes = this.countryCodeService.getSupportedCountries().map((code: CountryCode) => {
      return {
        countryCode: code,
        countryCallingCode: getCountryCallingCode(code)
      }
    })
  }

  ngOnInit(): void {

  }

  selectCode() {
    if (this.saveCodeToLocalStorage)
      this.countryCodeService.putCodeInLocalStorage(this.codeFormControl.value)
    this.codeSelected.emit(this.codeFormControl.value.countryCode)
  }

  compareFunction(obj1: any, obj2: any) {
    return obj1.countryCode && obj1.countryCode == obj2.countryCode
  }

}
