import { OutboundOrder } from "src/app/core/models/order/outboundOrder";

export namespace OutboundOrdersPayloads {
    export type ConnectOutboundOrder = string;
    export type ConnectSupplierOrders = string;
}

export namespace OutboundOrdersActions {

    export class ConnectOutboundOrdersByOrgUser {
        public static readonly type = '[OutboundOrdersActions] ConnectOutboundOrders'
    }

    export class ConnectOutboundOrder {
        public static readonly type = '[OutboundOrdersActions] ConnectOutboundOrder'
        constructor(public payload: OutboundOrdersPayloads.ConnectOutboundOrder) { }
    }

    export class ConnectSupplierOrders {
        public static readonly type = '[OutboundOrdersActions] ConnectSupplierOrders'
        constructor(public payload: OutboundOrdersPayloads.ConnectSupplierOrders) { }
    }

    export class UpdateOutboundOrder {
        public static readonly type = '[OutboundOrdersActions] UpdateOutboundOrder'
        constructor(public outboundOrder: OutboundOrder) { }
    }


    export class GetMasterArticles {
        public static readonly type = '[OutboundOrdersActions] GetMasterArticles'
        constructor() { }
    }
}