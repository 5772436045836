import { AbstractControl, Validators } from "@angular/forms";
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export class CustomValidators extends Validators {

    static phone(countryCode: CountryCode = 'US') {
        return (control: AbstractControl) => {
            if (!control.value) {
                return null;
            }

            try {
                const phoneNumber = parsePhoneNumber(control.value, countryCode);
                return !phoneNumber.isValid() ? { invalid: true } : null;
            } catch (error) {
                return { invalid: true };
            }
        }
    }
}