export interface IWarehouseStockLevel{
    externalReferenceCode: string;
    stockLevel: number;
}

export class WarehouseStockLevel {
    externalReferenceCode: string;
    stockLevel: number;

    constructor(obj?: any){
        this.externalReferenceCode = obj && obj.externalReferenceCode || "";
        this.stockLevel = obj && obj.stockLevel || null;
    }

    toFirestore() {
      return {
        externalReferenceCode: this.externalReferenceCode,
        stockLevel: this.stockLevel
      }
    }
}
