<div class="login-form-container">

    <mat-card appearance="outlined" class="login-form-block">
      <h2 class="text-center">Prijavi se</h2>
      <form [formGroup]="loginForm" (ngSubmit)="logIn()">
  
        <mat-form-field class="example-full-width">
          <input matInput placeholder="e-Mail" formControlName="email" autocomplete="off" autofocus />
          <mat-error *ngIf="loginForm.controls.email.hasError('required')">Ovo polje je obavezno</mat-error>
          <mat-error *ngIf="loginForm.controls.email.hasError('email')">E-mail adresa nije validna</mat-error>
        </mat-form-field>
  
        <br />
  
        <mat-form-field class="example-full-width">
          <input matInput placeholder="password" formControlName="password" autocomplete="off" type="password"
            autofocus />
          <mat-error *ngIf="loginForm.controls.password.hasError('required')">Ovo polje je obavezno</mat-error>
        </mat-form-field>
  
        <div class="login-form-actions">
          <div class="text-right">
            <button type="submit" mat-raised-button color="accent">Potvrdi</button>
          </div>
        </div>
  
      </form>
    </mat-card>
  </div>
  