export interface IcustomerSalesRepresentative {
    externalReferenceCode: string;
}

export class CustomerSalesRepresentative {
    externalReferenceCode: string;

    constructor(obj?: any){
        this.externalReferenceCode = obj && obj.externalReferenceCode || "";
    }

    toFirestore(): any{
        return {
            externalReferenceCode: this.externalReferenceCode
        };
    }
    fromFirestore(snapshot: any, options: any) {
        const data = snapshot.data(options);
        return new CustomerSalesRepresentative(data);
    }
}