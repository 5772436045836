export interface INote {
    createdByUserId: string;
    createdByUserName: string;
    categoryId: string;
    createdAt: Date;
    message: string;
}

export class Note {
    createdByUserId: string;
    createdByUserName: string;
    categoryId: string;
    createdAt: Date;
    message: string;

    constructor(obj?: any){
        this.createdByUserId = obj && obj.createdByUserId || "";
        this.createdByUserName = obj && obj.createdByUserName || "";
        this.categoryId = obj && obj.categoryId || "";
        this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
      obj.createdAt.toDate() || obj.createdAt) || null;
        this.message = obj && obj.message || ""
    }

    toFirestore (): any {
        return {
            createdByUserId: this.createdByUserId,
            createdByUserName: this.createdByUserName,
            categoryId: this.categoryId,
            createdAt: this.createdAt,
            message: this.message
            };
    }
    fromFirestore (snapshot: any, options: any): any{
        const data = snapshot.data(options);
        return new Note(data);
    }
}
