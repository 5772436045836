<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [style.height]="'auto'">
    <mat-toolbar *ngIf="userOrganizations.length < 2">
      {{organization.name}}
    </mat-toolbar>
    <mat-toolbar *ngIf="userOrganizations.length >= 2">
      <mat-form-field>
        <mat-select [(value)]="selectedOrg" [compareWith]="compareOrgs" (selectionChange)="changeOrg()">
          <mat-option *ngFor="let org of userOrganizations" [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </mat-form-field>

    </mat-toolbar>
    <mat-nav-list>
      <ng-container *ngFor="let route of routes">
        <a mat-list-item *ngIf="isVisibleToUser(route.roles) && isVisibleToOrg(route.moduleName)"
          [routerLink]="[route.path]" (click)="drawer.toggle()">{{route.name}}</a>
      </ng-container>
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="'profile'">Profil</a>
      <a mat-list-item (click)="signOut()">Odjavi se</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <!-- <span [style.overflow]="'hidden'">{{organization.name}} - {{title | async}}</span> -->
      <span [style.overflow]="'hidden'">{{title | async}}</span>
      <span class="flexExpand"></span>
      <button mat-button (click)="navigateBack()" type="button">
        <mat-icon appClickStopPropagation (click)="navigateBack()">arrow_back</mat-icon>
      </button>
    </mat-toolbar>
    <!-- <router-outlet *ngIf="userReady"></router-outlet> -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>