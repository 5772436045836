import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appArticleStyling]'
})
export class ArticleStylingDirective implements OnInit, OnChanges{

  @Input() note: string = ''
  @Input() appArticleStyling: string | undefined = ''

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit(){
    this.el.nativeElement.style.color = this.setStyleColor(this.appArticleStyling)
    this.el.nativeElement.style.textDecoration = this.setStyleDecoration(this.appArticleStyling)
    //this.setStyles()
  }

  ngOnChanges(): void {
    this.el.nativeElement.style.color = this.setStyleColor(this.appArticleStyling)
    this.el.nativeElement.style.textDecoration = this.setStyleDecoration(this.appArticleStyling)

    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

  }


  setStyleColor(note: any) {
    if (note == 'replacement' || note == 'replaced') {
      return 'green'
    } else if (note == 'cancelled' || note == 'out_of_stock') {
      return 'red'
    } else if (note == 'added'){
      return 'blue'
    } else if (note == 'modified_quantity') {
      return 'orange'
    } else if (note == 'supplement') {
      return 'purple'
    } else {
      return ''
    }
  }

  setStyleDecoration(note: any) {
    if (note == 'cancelled' || note == 'replaced' || note == 'out_of_stock') {
      return 'line-through'
    } else {
      return ''
    }
  }


}
