<mat-card class="card-margin" appearance="outlined">
    <mat-card-header>
        <mat-card-subtitle><strong>Šifra:</strong>{{article.code}} \ <strong>JM:</strong>
            {{article.unitOfMeasurement
            }}</mat-card-subtitle>
        <mat-card-title>{{ article.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div *ngIf="article.standardSalesPrice">
            Cena: {{ article.standardSalesPrice | currency: currencyCode }}
        </div>
        <div *ngIf="article.maxDiscount">
            Makismalni rabat: {{ article.maxDiscount }} %
        </div>
        <b><span [ngStyle]="{'white-space':'nowrap', 'display':'inline'}">Zalihe ({{ article._integration.lastSyncDate |
                date: "dd/MM/yyyy HH:mm"}}):</span></b><br>
        <div *ngFor="let level of article.warehouses">
            Magacin: {{ level.externalReferenceCode }}: {{ level.stockLevel }} - {{ article.unitOfMeasurement }}
        </div>
    </mat-card-content>
</mat-card>