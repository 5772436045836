import { IOrderCustomerDeliveryLocation, OrderCustomerDeliveryLocation } from './orderCustomerDeliveryLocation';
import { IOrderCustomerContactPerson, OrderCustomerContactPerson } from './orderCustomerContactPerson';
import { CustomerRegion } from '../../customer/customerRegion';
import { CustomerPaymentInformation } from '../../customer/customerPaymentInformation';

export interface IOrderCustomer {
    id: string;
    name: string;
    taxId: string;
    deliveryLocation: IOrderCustomerDeliveryLocation;
    deliveryRegion: string;
    contactPersons: Array<IOrderCustomerContactPerson>;
    ERPDeliveryLocationReferenceCode: string;
    ERPCustomerReferenceCode: string;
    paymentInformation: CustomerPaymentInformation;
    region: CustomerRegion;
    registrationId: string; 
}

export class OrderCustomer {
    id: string;
    name: string;
    taxId: string;
    deliveryLocation: OrderCustomerDeliveryLocation;
    deliveryRegion: string;
    contactPersons: Array<OrderCustomerContactPerson>;
    ERPDeliveryLocationReferenceCode: string;
    ERPCustomerReferenceCode: string;
    paymentInformation: CustomerPaymentInformation;
    region: CustomerRegion;
    registrationId: string;

    constructor(obj?: any){
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.taxId = obj && obj.taxId || "";
        this.deliveryLocation = obj && new OrderCustomerDeliveryLocation(obj.deliveryLocation) || new OrderCustomerDeliveryLocation();
        this.deliveryRegion = obj && obj.deliveryRegion || "";
        this.contactPersons = obj && obj.contactPersons && obj.contactPersons.map((person: any) => new OrderCustomerContactPerson(person)) || [];
        this.ERPDeliveryLocationReferenceCode = obj &&  obj.ERPDeliveryLocationReferenceCode || "";
        this.ERPCustomerReferenceCode = obj && obj.ERPCustomerReferenceCode || "";
        this.paymentInformation = obj && new CustomerPaymentInformation(obj.paymentInformation) || new CustomerPaymentInformation();
        this.region = obj && new CustomerRegion(obj.region) || new CustomerRegion();
        this.registrationId = obj && obj.registrationId || "";
    }

    toFirestore(): any {
        return {
            id: this.id,
            name: this.name,
            taxId: this.taxId,
            deliveryLocation: this.deliveryLocation.toFirestore(),
            deliveryRegion: this.deliveryRegion,
            contactPersons: this.contactPersons.map((person: OrderCustomerContactPerson) => person.toFirestore()),
            ERPDeliveryLocationReferenceCode: this.ERPDeliveryLocationReferenceCode,
            ERPCustomerReferenceCode: this.ERPCustomerReferenceCode,
            paymentInformation: this.paymentInformation.toFirestore(),
            region: this.region.toFirestore(),
            registrationId: this.registrationId
            };
    }

    fromFirestore(snapshot: any, options: any){
        const data = snapshot.data(options);
        return new OrderCustomer(data);
    }
}
