import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  logIn() {
    if (this.loginForm.valid){
      this.loginForm.disable();
      this.authService.logIn(this.loginForm.value.email, this.loginForm.value.password).subscribe( () => {
        this.router.navigate(['']);
      }, error => {
    }
    )
    }
  }

}
