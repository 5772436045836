import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentData, QuerySnapshot } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription, firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/auth/services/user.service';
import { IOrganization, Organization } from '../models/organization/organization';
import { map, take } from 'rxjs/operators';
import { resolve } from 'dns';
import { UserOrganizationsActions } from 'src/app/profile/states/user-organizations/user-organizations.actions';
import { Store } from '@ngxs/store';

export const NO_ORG_ID = "NO_ORG"
export const NO_ORG = new Organization({
  id: NO_ORG_ID
})

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private dbPath = '/organizations';
  organizationRef: AngularFirestoreCollection<IOrganization>;
  orgSubject = new BehaviorSubject<Organization>(NO_ORG);
  organization$ = this.orgSubject.asObservable()

  // orgSub!: Subscription;

  constructor(
    private userService: UserService,
    private store: Store,
    private db: AngularFirestore
  ) {

    this.organizationRef = this.db.collection(this.dbPath);
  }

  getOrganization(): Organization {
    return this.orgSubject.value;
  }

  orgAsObservable(): Observable<Organization> {
    return this.organization$;
  }

  fetchOrganizationByTaxId(taxId: string, countryCode: string) {
    return this.db.collection('organizations', (ref) => ref.where('taxId', '==', taxId).where('countryCode', '==', countryCode)).valueChanges().pipe(
      take(1),
      map((data: any) => {
        return data.map((item: any) => {
          return new Organization(item);
        });
      }),
    )
  }

  async setUserOganization(organizationId: string) {
    const listOfOrg = await this.getAllOrganizationByIds([organizationId])
    const _newOrg = listOfOrg.find((o: IOrganization) => o.id == organizationId)
    if (_newOrg) {
      this.orgSubject.next(new Organization(_newOrg))
      localStorage.setItem('orgId', _newOrg.id);
    }
  }

  async switchOrganization(orgId: string) {
    var _userFS = this.userService.getCurrentUser()
    if (_userFS) {
      await this.userService.updateCurrentUser(_userFS.id, orgId, _userFS.orgIds)
      await this.setUserOganization(orgId)

      this.store.dispatch(new UserOrganizationsActions.SwitchOrganization(orgId))
    }
  }

  getCurrentOrganizationId(): string {
    return localStorage.getItem("orgId") || "";
  }

  async getAllOrganizationByIds(docIds: string[]): Promise<IOrganization[]> {
    try {

      const docPromises = docIds.map(async (docId) => {
        const docRef = this.organizationRef.doc(docId);
        const docSnapshot = await firstValueFrom(docRef.get());
        if (docSnapshot.exists) {
          return new Organization(docSnapshot.data());
        } else {
          throw new Error(`Document with ID ${docId} not found.`);
        }
      });

      const documents = await Promise.all(docPromises);
      return documents;
    } catch (error) {
      throw new Error(`Error fetching documents: ${error}`);
    }
  }

}
