import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../components/loading-component/loading.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private dialogRef: any;

  constructor(private dialog: MatDialog) { }

  // show() {    
  //   this.loadingSubject.next(true);
  // }

  // hide() {
  //   this.loadingSubject.next(false);
  // }

  public show() {
    this.loadingSubject.next(true);

    this.dialogRef = this.dialog.open(LoadingComponent, {
      width: '50px',
      height: '50px',
      panelClass: 'transparent', // Custom class for styling
      disableClose: true // Prevents closing the dialog by clicking outside or pressing ESC
    });
  }

  public hide() {
    this.loadingSubject.next(false);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
