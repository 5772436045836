
import { Injectable } from '@angular/core';
import { UserService } from 'src/app/auth/services/user.service';
import { SnackbarService } from './snackbar.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  // currentMessage = new BehaviorSubject<any | null>(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private userService: UserService,
    private snackbar: SnackbarService,
  ) { }

  requestPermission() {
    this.angularFireMessaging.requestPermission.subscribe((permission) => {
      console.log(`Notification permission:${permission}`);
      if (permission == 'granted') {
        this.angularFireMessaging.requestToken.subscribe((deviceToken) => {
          if (deviceToken)
            this.userService.saveNotificationToken(deviceToken);
        })
      }
    })

  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log(">>>>>>>>>Message received.<<<<<<<<<<", payload);
        if (payload) {
          const remoteMsg = payload?.notification?.body ?? ""
          this.snackbar.info(remoteMsg)
        }
      });
  }

}
