<div formArrayName="locations" *ngFor="let location of locationsControls; let i = index;" [style.margin-left.px]="'9'">
    <div [formGroupName]="i">
       Adresa dostavnog mesta {{i+1}}:<br>
       <mat-form-field clsas="width-100">
           <input type="text" formControlName="locationName" matInput placeholder="Naziv lokacije" tabindex="10"
               autocomplete="off">
           <mat-error *ngIf="location.controls['locationName'].hasError('requred')">Ovo polje je
               obavezno</mat-error>
       </mat-form-field><br>
       <mat-form-field clsas="width-100">
           <input type="text" formControlName="address" matInput placeholder="Ulica i broj" tabindex="11"
               autocomplete="off">
           <mat-error *ngIf="location.controls['address'].hasError('requred')">Ovo polje je
               obavezno</mat-error>
       </mat-form-field><br>
       <mat-form-field clsas="width-100">
           <input type="text" formControlName="city" matInput placeholder="Grad" tabindex="12"
               autocomplete="off">
           <mat-error *ngIf="location.controls['city'].hasError('requred')">Ovo polje je
               obavezno</mat-error>
       </mat-form-field><br>
       <mat-form-field clsas="width-100">
           <input type="text" formControlName="postalCode" matInput placeholder="Poštanski broj" tabindex="13"
               autocomplete="off">
           <mat-error *ngIf="location.controls['postalCode'].hasError('requred')">Ovo polje je obavezno
           </mat-error>
       </mat-form-field>
       <div *ngIf="i>0">
           <button type="button" mat-button (click)="removeLocation(i)">Obrisi lokaciju</button>
       </div>
    </div>
</div>
