import { InboundOrderSupplierSalesRep } from "./inboundOrderSupplierSalesRep";

export interface IInboundOrderSupplier{
    orgId: string;
    name: string;
    taxId: string;
    deliveryVehicle: string;
    deliveryRegion: string;
    fulfilmentOrg: string;
    salesRep: InboundOrderSupplierSalesRep
}

export class InboundOrderSupplier{
    id: string;
    name: string;
    taxId: string;
    deliveryVehicle: string;
    deliveryRegion: string;
    fulfilmentOrg: string;
    salesRep: InboundOrderSupplierSalesRep

    constructor(obj?: any){
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.taxId = obj && obj.taxId || "";
        this.deliveryVehicle = obj && obj.deliveryVehicle || "";
        this.deliveryRegion = obj && obj.deliveryRegion || "";
        this.fulfilmentOrg = obj && obj.fulfilmentOrg || "";
        this.salesRep = obj && obj.salesRep && new InboundOrderSupplierSalesRep(obj.salesRep) || new InboundOrderSupplierSalesRep()
    }


    toFirestore(): any {
        return {
            id: this.id,
            name: this.name,
            taxId: this.taxId,
            deliveryVehicle: this.deliveryVehicle,
            deliveryRegion: this.deliveryRegion,
            fulfilmentOrg: this.fulfilmentOrg,
            salesRep: this.salesRep.toFirestore()
            }
    }

    fromFirestore (snapshot: any, options: any): any{
        const data = snapshot.data(options);
        return new InboundOrderSupplier(data);
    }
}
