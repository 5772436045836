import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserFS } from 'src/app/core/models/user/user';
import { NO_TEFTER_ACCOUNT, UserService } from '../services/user.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  [x: string]: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {

    const currentRoute = state.url;

    return combineLatest([
      this.userService.userAsObservable(), this.authService.idToken$
    ]).pipe(
      skipWhile(([userFS, token]: [UserFS | undefined, any]) => {
        if (token == "TOKEN_PENDING") {
          if (userFS?.id == "NO_USER") {
            console.log("no user. Go to login")

            this.router.navigate(['login']);
            return false
          }

          console.log("TOKEN_PENDING")
          return true
        }
        if (!userFS) {
          console.log("no user")
          return true;
        }
        return false;
      }),
      map(([userFS, token]: [UserFS | undefined, any]) => {
        if (!token) {
          console.log("token not found")
          this.navigateToSpecificPage(currentRoute, '/auth');
          return false
        }
        if (!userFS || !userFS.id || userFS.id == "NO_USER" || userFS.registrationStatus == NO_TEFTER_ACCOUNT) {
          console.log("firestore user not yet fetched")
          this.navigateToSpecificPage(currentRoute, '/account/register-user');
          return false;
        }

        if (!userFS.orgId) {

          this.navigateToSpecificPage(currentRoute, '/account/register-organization');

          return false;
        }

        if (!token.claims.orgId && !token.claims.orgIds) {
          console.log("user has no claims to org")
          this.navigateToSpecificPage(currentRoute, '/auth');
          return false
        }

        // console.log("TOKEN authguard IMA:", token)
        return true;
      }))

  }

  navigateToSpecificPage(currentRout: String, urlPath: String) {
    if (currentRout != urlPath) {
      this.router.navigate([urlPath]);
    }
  }

}
