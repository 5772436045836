
export interface IArticleCategory {
  articles: Array<string>;
  categories: IArticleCategory[];
  code: string;
  externalReferenceCode: string;
  name: string;
  subcategories: IArticleCategory[]
}

export class ArticleCategory implements IArticleCategory {
  articles: Array<string>;
  categories: ArticleCategory[];
  code: string;
  externalReferenceCode: string;
  name: string;
  subcategories: ArticleCategory[];

  constructor(obj?: any) {
    this.articles = obj && obj.articles || [];
    this.categories = obj && obj.categories && obj.categories.map((category: any) => new ArticleCategory(category)) || [];
    this.code = obj && obj.code || "";
    this.externalReferenceCode = obj && obj.externalReferenceCode || "";
    this.name = obj && obj.name || "";
    this.subcategories = obj && obj.subcategories && obj.subcategories.map((category: any) => new ArticleCategory(category)) || [];
  }

  toFirestore() {
    return {
      code: this.code,
      externalReferenceCode: this.externalReferenceCode,
      name: this.name,
      subcategory: this.subcategories
    }
  }
}
