import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'partnershipStatus'
})
export class PartnershipStatusPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
    case 'NONE':
      return  ""
    case 'REQUESTED':
      return "Zahtev poslat"
    case 'REJECTED':
      return "Zahtev odbijen"
    case 'ACCEPTED':
      return "Partner"
    default:
      return ""
    }
  }

}
