import { Injectable } from '@angular/core';
import { CountryCallingCode, CountryCode } from 'libphonenumber-js';
import { BehaviorSubject } from 'rxjs';
import { Organization } from '../models/organization/organization';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root'
})
export class CountryCodeService {

  supportedCountries: CountryCode[] = ['RS', 'ME']
  defualtCountryCode = {
    countryCode: 'RS',
    countryCallingCode: '381'
  }
  orgCountryCode: CountryCode = 'RS';
  private orgResolvedSubject = new BehaviorSubject<boolean>(false)
  orgResolved = this.orgResolvedSubject.asObservable()

  constructor(private orgService: OrganizationService) {
    this.orgService.orgAsObservable().subscribe((org: Organization) => {
      if (!org || !org.id) {
        this.orgResolvedSubject.next(false)
        return
      }
      this.orgCountryCode = org.countryCode as CountryCode || 'RS'
      this.orgResolvedSubject.next(true)
    })
  }

  getSupportedCountries(): CountryCode[] {
    return this.supportedCountries;
  }

  getDefaultCountryCode(): { countryCode: string, countryCallingCode: string } {
    return this.defualtCountryCode;
  }

  getCodeFromLocalStorage(): { countryCode: string, countryCallingCode: string } {
    const localStorageCode = localStorage.getItem("countryCode")
    const codeValue = localStorageCode ? JSON.parse(localStorageCode) : this.getDefaultCountryCode()
    return codeValue;
  }

  putCodeInLocalStorage(coutnryCode: { countryCode: string, countryCallingCode: string }) {
    localStorage.setItem("countryCode", JSON.stringify(coutnryCode))
  }

  getOrgCountryCode(): string {
    return this.orgCountryCode;
  }


}
