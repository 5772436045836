<style>
    @media screen and (min-width: 768px) {
        .button-container{
            justify-content: center !important;
            gap: 5px;
        }
    }
</style>
<br>
<div [style.text-align]="'center'">
    <mat-card appearance="outlined" >
        <mat-card-title>{{order.supplier.name}}</mat-card-title>
        <hr>
        <br>
        <mat-card-content >
            <mat-icon matTooltip="Broj porudžbine">tag</mat-icon> <span>
                {{order.number}}
            </span>
            <br>
        <mat-icon  matTooltip="Datum kreiranja porudžbine">event</mat-icon> {{
         convertDate(order.createdAt) | date: 'dd/MM/yyyy HH:mm'}} <br>
        <mat-icon matTooltip="Datum poslednje izmene porudžbine">edit_calendar</mat-icon> {{
        order.lastModifiedAt | date:
        'dd/MM/yyyy HH:mm'}} <br>
        <mat-icon  matTooltip="Traženi datum isporuke">pending_actions</mat-icon> {{
        convertDate(order.requestedDeliveryDate)  | date: 'dd/MM/yyyy'}} <br>
        <mat-icon matTooltip="Mesto isporuke">place</mat-icon> {{
        order.customer.deliveryLocation.fullAddressText }} <br>
        </mat-card-content>
              <button routerLink="['/app/procurement/suppliers/outbound-order-edit/', order.id]" [style.-opacity]="0.4">
        Izmeni Porudžbinu
    </button>
    </mat-card>
    <br>
    <div class="article-container" [style.display]="'flex'" [style.flex-wrap]="'wrap'" >
        <div class="articles" [style.width]="'25%'"  *ngFor="let article of articles">
            <!-- <pro-article-item [article]="article" [quantity]="(articleQuantities | async)?.get(article.id)?.quantity || 0" (onEdit)="openEditArticleDialog(article)"
            (onDelete)="deleteArticle(article)" (quantityChanged)="changeQuantity(article, $event)"></pro-article-item> -->
        </div>
    </div>

    <br>
    <br>
    <div class="button-container" [style.display]="'flex'" [style.justify-content]="'space-between'">
        <button (click)="openDialog()" mat-button [style.background-color]="'#3f51b5'" [style.color]="'white'">Prihvati</button>
        <button (click)="openDialogDecline()"  mat-button [style.background-color]="'#f44336'" [style.color]="'white'">Odbi</button>
    </div>
</div>
