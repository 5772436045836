export interface IOrganizationConfig {
    activeModules: OrganizationConfigs.IActiveModules | null
    integration: OrganizationConfigs.IIntegration | null
    orderLabels: OrganizationConfigs.IOrderLabels | null
    orderStatusCodes: OrganizationConfigs.IOrderStatusCodes | null
    splitInboundOrderArticles: OrganizationConfigs.ISplitInboundOrderArticles | null
    warehouseOrdersManagement: OrganizationConfigs.IWarehouseOrdersManagement | null
}


export class OrganizationConfig implements IOrganizationConfig {
    activeModules: OrganizationConfigs.ActiveModules | null
    integration: OrganizationConfigs.Integration
    orderLabels: OrganizationConfigs.OrderLabels | null
    orderStatusCodes: OrganizationConfigs.OrderStatusCodes | null
    splitInboundOrderArticles: OrganizationConfigs.SplitInboundOrderArticles | null
    warehouseOrdersManagement: OrganizationConfigs.WarehouseOrdersManagement | null

    constructor(obj?: any) {
        this.activeModules = obj && obj.activeModules && new OrganizationConfigs.ActiveModules(obj.activeModules) || null;
        this.integration = obj && obj.integration && new OrganizationConfigs.Integration(obj.integration) || null;
        this.orderLabels = obj && obj.orderLabels && new OrganizationConfigs.OrderLabels(obj.orderLabels) || null;
        this.orderStatusCodes = obj && obj.orderStatusCodes && new OrganizationConfigs.OrderStatusCodes(obj.orderStatusCodes) || null;
        this.splitInboundOrderArticles = obj && obj.splitInboundOrderArticles && new OrganizationConfigs.SplitInboundOrderArticles(obj.splitInboundOrderArticles) || null;
        this.warehouseOrdersManagement = obj && obj.warehouseOrdersManagement && new OrganizationConfigs.WarehouseOrdersManagement(obj.warehouseOrdersManagement) || null;
    }
}

export namespace OrganizationConfigs {

    export interface IActiveModules {
        id: string;
        createdAt: Date;
        expenses: boolean,
        procurement: boolean,
        profile: boolean,
        salesrep: boolean,
        settings: boolean,
        warehouseOrders: boolean
    }

    export interface IIntegration {
        id: string;
        createdAt: Date;
        salesreps: boolean,
        customers: boolean,
        catalog: boolean,
        warehouses: boolean,
        suppliers: boolean,
        inboundOrders: boolean
    }

    export interface IOrderLabels {
        id: string;
        createdAt: Date;
        labels: string[]
    }

    export interface IOrderStatusCodes {
        id: string;
        createdAt: Date;
        codes: string[];
    }

    export interface ISplitInboundOrderArticles {
        id: string;
        createdAt: Date;
        by: ISplitInboundOrderArticlesBy
    }

    export interface ISplitInboundOrderArticlesBy {
        attribute: string;
        values: ISplitInboundOrderArticlesValue[]

    }

    export interface ISplitInboundOrderArticlesValue {
        externalReferenceCodes: string[]
        forwardToOrgId: string
    }

    export interface IWarehouseOrdersManagement {
        id: string;
        createdAt: Date;
        allowArticleReplacement: boolean;
        requireRouteSelection: boolean;
        requireVehicleSelection: boolean;
        showPrices: boolean
    }

    export class ActiveModules implements IActiveModules {
        id: string;
        createdAt: Date;
        expenses: boolean;
        procurement: boolean;
        profile: boolean;
        salesrep: boolean;
        settings: boolean;
        warehouseOrders: boolean;

        constructor(obj?: any) {
            this.id = obj && obj.id || "";
            this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
                obj.createdAt.toDate() || obj.createdAt) || null;
            this.expenses = Boolean(obj?.expenses);
            this.procurement = Boolean(obj?.procurement);
            this.profile = Boolean(obj?.profile);
            this.salesrep = Boolean(obj?.salesrep);
            this.settings = Boolean(obj?.settings);
            this.warehouseOrders = Boolean(obj?.warehouseOrders);
        }

        toFirestore(): any {
            return {
                id: this.id,
                createdAt: this.createdAt,
                expenses: this.expenses,
                procurement: this.procurement,
                profile: this.profile,
                salesrep: this.salesrep,
                settings: this.settings,
                warehouseOrders: this.warehouseOrders
            }
        }
    }

    export class Integration implements IIntegration {
        id: string;
        createdAt: Date;
        salesreps: boolean
        customers: boolean
        catalog: boolean
        warehouses: boolean
        suppliers: boolean
        inboundOrders: boolean

        constructor(obj?: any) {
            this.id = obj && obj.id || "";
            this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
                obj.createdAt.toDate() || obj.createdAt) || null;
            this.salesreps = Boolean(obj?.salesreps);
            this.customers = Boolean(obj?.customers);
            this.catalog = Boolean(obj?.catalog);
            this.warehouses = Boolean(obj?.warehouses);
            this.suppliers = Boolean(obj?.suppliers);
            this.inboundOrders = Boolean(obj?.inboundOrders);
        }

        toFirestore(): any {
            return {
                id: this.id,
                createdAt: this.createdAt,
                salesreps: this.salesreps,
                customers: this.customers,
                catalog: this.catalog,
                warehouses: this.warehouses,
                suppliers: this.suppliers,
                inboundOrders: this.inboundOrders,
            }
        }
    }

    export class OrderLabels implements IOrderLabels {
        id: string;
        createdAt: Date;
        labels: string[];

        constructor(obj?: any) {
            this.id = obj && obj.id || "";
            this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
                obj.createdAt.toDate() || obj.createdAt) || null;
            this.labels = obj && obj.labels || [];
        }

        toFirestore(): any {
            return {
                id: this.id,
                createdAt: this.createdAt,
                codes: this.labels
            }
        }
    }

    export class OrderStatusCodes implements IOrderStatusCodes {
        id: string;
        createdAt: Date;
        codes: string[];

        constructor(obj?: any) {
            this.id = obj && obj.id || "";
            this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
                obj.createdAt.toDate() || obj.createdAt) || null;
            this.codes = obj && obj.codes || [];
        }

        toFirestore(): any {
            return {
                id: this.id,
                createdAt: this.createdAt,
                codes: this.codes
            }
        }
    }



    export class SplitInboundOrderArticles implements ISplitInboundOrderArticles {
        id: string;
        createdAt: Date;
        by: ISplitInboundOrderArticlesBy


        constructor(obj?: any) {
            this.id = obj && obj.id || "";
            this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
                obj.createdAt.toDate() || obj.createdAt) || null;
            this.by = obj && new SplitInboundOrderArticlesBy(obj.by) || new SplitInboundOrderArticlesBy();
        }
    }

    export class SplitInboundOrderArticlesBy implements ISplitInboundOrderArticlesBy {
        attribute: string;
        values: ISplitInboundOrderArticlesValue[]

        constructor(obj?: any) {
            this.attribute = obj && obj.attribute || "";
            this.values = obj && obj.values && obj.values.map((elem: any) => new SplitInboundOrderArticlesValue(elem)) || [];
        }

    }

    export class SplitInboundOrderArticlesValue implements ISplitInboundOrderArticlesValue {
        externalReferenceCodes: string[]
        forwardToOrgId: string

        constructor(obj?: any) {
            this.externalReferenceCodes = obj && obj.externalReferenceCodes || [];
            this.forwardToOrgId = obj && obj.forwardToOrgId || ""
        }
    }

    export class WarehouseOrdersManagement implements IWarehouseOrdersManagement {
        id: string;
        createdAt: Date;
        allowArticleReplacement: boolean;
        requireRouteSelection: boolean;
        requireVehicleSelection: boolean;
        showPrices: boolean;

        constructor(obj?: any) {
            this.id = obj && obj.id || "";
            this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
                obj.createdAt.toDate() || obj.createdAt) || null;
            this.allowArticleReplacement = Boolean(obj?.allowArticleReplacement);
            this.requireRouteSelection = Boolean(obj.requireRouteSelection);
            this.requireVehicleSelection = Boolean(obj.requireVehicleSelection);
            this.showPrices = Boolean(obj.showPrices);
        }

        toFirestore(): any {
            return {
                id: this.id,
                createdAt: this.createdAt,
                allowArticleReplacement: this.allowArticleReplacement,
                requireRouteSelection: this.requireRouteSelection,
                requireVehicleSelection: this.requireVehicleSelection,
                showPrices: this.showPrices
            }
        }
    }

}




