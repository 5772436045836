export interface ICustomerRegion {
  externalReferenceCode: string;
  name: string;
  subRegion: ICustomerRegion
}

export class CustomerRegion {
  externalReferenceCode: string;
  name: string;
  subRegion: CustomerRegion

  constructor(obj?: any){
    this.externalReferenceCode = obj && obj.externalReferenceCode || "",
    this.name = obj && obj.name || ""
    this.subRegion = obj && obj.subRegion && new CustomerRegion(obj.subRegion) || ""
  }

  toFirestore(): any {
    return {
        externalReferenceCode: this.externalReferenceCode,
        name: this.name,
        subRegion: this.subRegion ? this.subRegion.toFirestore() : ""
    };
}
fromFirestore(snapshot: any, options: any) {
    const data = snapshot.data(options);
    return new CustomerRegion(data);
}
}
