import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Article } from 'src/app/core/models/article/article';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'pro-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.sass']  
})
export class EditArticleComponent implements OnInit {

  form: FormGroup;

  article: Article;


  constructor(
    public dialogRef: MatDialogRef<EditArticleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Article,
    private fb: FormBuilder
    ) { 
      this.form = fb.group({
        name: new FormControl('', [Validators.required]),
        code: new FormControl('', [Validators.required]),
        transportPackageQuantity: new FormControl(0, [Validators.required]),
        unitOfMeasurement: new FormControl('', [Validators.required])
      })
      this.article = new Article({...data});
    }

  ngOnInit(): void {
  }

  onSubmit() {
    this.dialogRef.close(this.article);
  }

  onCancel() {
    this.dialogRef.close(undefined);
  }

}
