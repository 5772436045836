import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrgUser } from 'src/app/core/models/user/user';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {

    let expecedRoles = next.data.roles;
    const currentRoute = state.url;

    const result = new Promise<boolean>((resolve, reject) => {
      this.userService.orgUserAsObservable().subscribe((user: OrgUser) => {
        if (!user.id) return;
        for (let role of user.roles) {
          if (expecedRoles.includes(role)) {
            resolve(true);
            return;
          }
        }
        this.navigateToSpecificPage(currentRoute, '/app/home');
        resolve(false)
      })
    })
    return result
  }

  navigateToSpecificPage(currentRout: String, urlPath: String) {
    if (currentRout != urlPath) {
      this.router.navigate([urlPath]);
    }
  }
}
