import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerSearchPipe'
})
export class CustomerSearchPipe implements PipeTransform {

  transform(customers: any[], searchText: string): any[] {
    // console.log("lista kustomera:", customers)
    if (!customers || !searchText) {
      return customers;
    }

    searchText = this.replaceSerbianLatin(searchText);

    return customers.filter(c => {
      const nameMatches = this.replaceSerbianLatin(c.fullName.toLowerCase()).includes(this.replaceSerbianLatin(searchText.toLowerCase()))
      const codeMatches = c.externalReferenceCode.toLowerCase().includes(searchText.toLowerCase())
      return nameMatches || codeMatches
    })
  }

  private replaceSerbianLatin(text: string): string {
    const replacements: Replacements = { 'č': 'c', 'ć': 'c', 'đ': 'dj', 'š': 's', 'ž': 'z' };
    return text.replace(/[čćđšž]/gi, match => replacements[match.toLowerCase()]);
  }

}

interface Replacements {
  [key: string]: string;
}