import { OrderCustomer } from "../order/customer/orderCustomer";
import { OrderCustomerContactPerson } from "../order/customer/orderCustomerContactPerson";
import { OrderCustomerDeliveryLocation } from "../order/customer/orderCustomerDeliveryLocation";
import { SupportedCountries } from "../supportedCountries";
import { CustomerIntegrationInfo } from "./customerIntegrationInfo";
import { CustomerLocation } from "./customerLocation";
import { CustomerPaymentInformation } from "./customerPaymentInformation";
import { CustomerRegion } from "./customerRegion";
import { CustomerSalesRepresentative } from "./customerSalesRepresentative";

export interface ICustomer {
  _integration: CustomerIntegrationInfo;
  accountsReceivables: number;
  companyAddress: string;
  countryCode: SupportedCountries;
  externalReferenceCode: string;
  fullName: string;
  id: string;
  isInactive: boolean;
  isReadOnly: boolean;
  locations: Array<CustomerLocation>;
  overdueAccountsReceivables: number;
  paymentInformation: CustomerPaymentInformation;
  phoneNumber: string;
  registrationId: string;
  region: CustomerRegion;
  salesRep: CustomerSalesRepresentative;
  taxId: string;
}

export class Customer implements ICustomer {
  _integration: CustomerIntegrationInfo;
  accountsReceivables: number;
  companyAddress: string;
  countryCode: SupportedCountries;
  externalReferenceCode: string;
  fullName: string;
  id: string;
  isInactive: boolean;
  isReadOnly: boolean;
  locations: Array<CustomerLocation>;
  overdueAccountsReceivables: number;
  paymentInformation: CustomerPaymentInformation;
  phoneNumber: string;
  region: CustomerRegion;
  registrationId: string;
  salesRep: CustomerSalesRepresentative;
  taxId: string;
  avatarUrl: string;

  constructor(obj?: any) {
    this._integration = obj && new CustomerIntegrationInfo(obj._integration) || new CustomerIntegrationInfo()
    this.accountsReceivables = obj && obj.accountsReceivables || 0
    this.companyAddress = obj && obj.companyAddress || ""
    this.countryCode = obj && obj.countryCode || SupportedCountries.RS
    this.externalReferenceCode = obj && obj.externalReferenceCode || ""
    this.fullName = obj && obj.fullName || ""
    this.id = obj && obj.id || ""
    this.isInactive = obj && obj.isInactive || false
    this.isReadOnly = obj && obj.isReadOnly || true
    this.locations = obj && obj.locations && obj.locations.map((x: any) => new CustomerLocation(x)) || []
    this.overdueAccountsReceivables = obj && obj.overdueAccountsReceivables || 0
    this.paymentInformation = obj && new CustomerPaymentInformation(obj.paymentInformation) || new CustomerPaymentInformation()
    this.phoneNumber = obj && obj.phoneNumber || ""
    this.registrationId = obj && obj.registrationId || ""
    this.region = obj && new CustomerRegion(obj.region) || new CustomerRegion()
    this.salesRep = obj && new CustomerSalesRepresentative(obj.salesRep) || new CustomerSalesRepresentative()
    this.taxId = obj && obj.taxId || ""
    this.avatarUrl = obj && obj.avatarUrl || ""
  }

  toFirestore(): any {
    return {
      _integration: this._integration.toFirestore(),
      accountsReceivables: this.accountsReceivables,
      companyAddress: this.companyAddress,
      countryCode: this.countryCode,
      externalReferenceCode: this.externalReferenceCode,
      fullName: this.fullName,
      id: this.id,
      isInactive: this.isInactive,
      isReadOnly: this.isReadOnly,
      locations: this.locations.map((location: CustomerLocation) => location.toFirestore()),
      overdueAccountsReceivables: this.overdueAccountsReceivables,
      paymentInformation: this.paymentInformation.toFirestore(),
      phoneNumber: this.phoneNumber,
      registrationId: this.registrationId,
      region: this.region.toFirestore(),
      salesRep: this.salesRep.toFirestore(),
      taxId: this.taxId,
      avatarUrl: this.avatarUrl
    };
  }



  toNewOrderCustomer(i: number): OrderCustomer {

    return new OrderCustomer({
      id: this.id,
      name: this.fullName,
      taxId: this.taxId,
      registrationId: this.registrationId,
      deliveryLocation: (this.locations && this.locations.length) ? this.locations[i].toOrderCustomerDeliveyLocation() : new OrderCustomerDeliveryLocation({ fullAddressText: this.companyAddress }),
      deliveryRegion: (this.locations && this.locations.length) ? this.locations[i].subregion.externalReferenceCode : "",
      contactPersons: [new OrderCustomerContactPerson()],
      ERPDeliveryLocationReferenceCode: (this.locations && this.locations.length) ? this.locations[i].externalReferenceCode : this.externalReferenceCode,
      ERPCustomerReferenceCode: this.externalReferenceCode,
      region: this.region,
      paymentInformation: this.paymentInformation
    })
  }

  fromFirestore(snapshot: any, options: any) {
    const data = snapshot.data(options);
    return new Customer(data);
  }
}
