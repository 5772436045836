import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryCurrencyConverter'
})
export class CountryCurrencyConverterPipe implements PipeTransform {

  transform(value: string): any {
    switch (value) {
      case "RS":
        return `RSD `
      case "ME":
        return "EUR"
    }
  }

}
