import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delivery-time',
  templateUrl: './delivery-time.component.html',
  styleUrls: ['./delivery-time.component.sass']
})
export class DeliveryTimeComponent implements OnInit {

  constructor() { }

  editPrice: boolean = false;

  @Input() requestedDeliveryDate!: Date
  @Input() deliverAsSoonAsPossible!: Boolean

  @Output() setRequestedDeliveryDate = new EventEmitter()
  @Output() switchDeliveryAsSoonAsPossible = new EventEmitter()

  ngOnInit(): void {
  }


  setDate(requestedDeliveryDate: Date) {
    // Add 6 hours to the requestedDeliveryDate
    const updatedDate = new Date(requestedDeliveryDate);
    updatedDate.setHours(updatedDate.getHours() + 6);
    // Update the local requestedDeliveryDate and emit the event
    this.requestedDeliveryDate = updatedDate;

    this.setRequestedDeliveryDate.emit(updatedDate);
  }

  delivery(switcher: boolean) {
    this.switchDeliveryAsSoonAsPossible.emit(switcher)
  }


}
