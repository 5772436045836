import { ERPIntegrationStatusCode } from "./erpIntegrationStatusCode"

export interface IERPIntegrationStatus{
    ERPIntegrationStatusCode: ERPIntegrationStatusCode;
    message: string;
    time: Date;
}

export class ERPIntegrationStatus {

    ERPIntegrationStatusCode: ERPIntegrationStatusCode;
    message: string;
    time: Date;

    constructor(obj?: any){
        this.ERPIntegrationStatusCode = obj && obj.ERPIntegrationStatusCode || "";
        this.message = obj && obj.message || "";
        this.time = obj && obj.time || null;
    }

    toFirestore(): any {
        return {
            ERPIntegrationStatusCode: this.ERPIntegrationStatusCode,
            message: this.message,
            time: this.time
        }

    }
    fromFirestore (snapshot: any, options: any): any{
        const data = snapshot.data(options);
        return new ERPIntegrationStatus(data);
    }
}
