import { OrderCustomerDeliveryLocation } from "../order/customer/orderCustomerDeliveryLocation";

export interface ICustomerLocation {
  address: string;
  externalReferenceCode: string;
  location: string;
  name: string;
  postalCode: string;
  subregion: { externalReferenceCode: string };
}

export class CustomerLocation {
  address: string;
  externalReferenceCode: string;
  location: string;
  name: string;
  postalCode: string;
  subregion: { externalReferenceCode: string };

  constructor(obj?: any) {
    this.address = obj && obj.address || "";
    this.externalReferenceCode = obj && obj.externalReferenceCode || "";
    this.location = obj && obj.location || "";
    this.name = obj && obj.name || "";
    this.postalCode = obj && obj.postalCode || "";
    this.subregion = obj && obj.subregion || { externalReferenceCode: "" }
  }

  toFirestore(): any {
    return {
      address: this.address,
      externalReferenceCode: this.externalReferenceCode,
      location: this.location,
      name: this.name,
      postalCode: this.postalCode,
      subregion: this.subregion
    };
  }

  fromFirestore(snapshot: any, options: any) {
    const data = snapshot.data(options);
    return new CustomerLocation(data);
  }

  toOrderCustomerDeliveyLocation() {
    return new OrderCustomerDeliveryLocation({
      fullAddressText: this.name.concat(', ', this.location).concat(', ', this.address),
      externalReferenceCode: this.externalReferenceCode,
      id: this.externalReferenceCode,
      name: this.name
    })
  }
}
