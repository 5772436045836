
export interface IArticleIntegrationData {
    lastSyncDate: Date;
    managedBy: string;
}

export class ArticleIntegrationData implements IArticleIntegrationData {

    lastSyncDate: Date;
    managedBy: string;

    constructor(obj?: any) {
        this.lastSyncDate = obj && obj.lastSyncDate && (typeof obj.lastSyncDate.toDate == 'function' &&
            obj.lastSyncDate.toDate() || obj.lastSyncDate) || null;
        this.managedBy = obj && obj.managedBy || '';
    }

    toFirestore() {
        return {
            lastSyncDate: this.lastSyncDate,
            managedBy: this.managedBy
        }
    }
}