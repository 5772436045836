import { CustomerContactPersonRole, ICustomerContactPersonRole } from '../../customer/customerContactPersonRole'

export interface IOrderCustomerContactPerson {
    description: string;
    email: string;
    role: ICustomerContactPersonRole;
    id: string;
    name: string;
    phone: string;
    salutation: string;

}

export class OrderCustomerContactPerson {
    description: string;
    email: string;
    role: CustomerContactPersonRole;
    id: string;
    name: string;
    phone: string;
    salutation: string;

    constructor(obj?: any){
        this.description = obj && obj.description || "";
        this.email = obj && obj.email || "";
        this.role = obj && obj.function && new CustomerContactPersonRole(obj.role) || "";
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.phone = obj && obj.phone || "";
        this.salutation = obj && obj.salutation || "";
    }

    toFirestore(): any {
        return {
            description: this.description,
            email: this.email,
            //function: this.function.toFirestore(),
            id: this.id,
            name: this.name,
            phone: this.phone,
            salutations: this.salutation
            };
    }

    fromFirestore(snapshot: any, options: any){
        const data = snapshot.data(options);
        return new OrderCustomerContactPerson(data);
    }
}
