export interface IOrderCustomerDeliveryLocation {
    id: string;
    fullAddressText: string;
    name: string;
}

export class OrderCustomerDeliveryLocation {
    id: string;
    fullAddressText: string;
    name: string;

    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.fullAddressText = obj && obj.fullAddressText || "";
        this.name = obj && obj.name || ""

    }

    toFirestore(): any {
        return {
            id: this.id,
            fullAddressText: this.fullAddressText,
            name: this.name
        };
    }
    fromFirestore(snapshot: any, options: any): any {
        const data = snapshot.data(options);
        return new OrderCustomerDeliveryLocation(data);
    }
}