
import { NotificationToken } from "./notification-token";

export interface IUser {
    fullName: string;
    id: string;
    orgId: string;
    phoneNumber: string;
}

export interface IUserSettings extends IUser {
    accountType: string;
    catalogId: string;
    isActivated: boolean;
    salesRepMatchId?: Array<string>;
}

// collection users
export interface IUserFS {
    creationTimestamp: string;
    fullName: string;
    id: string;
    orgId: string;
    orgIds: string[];
    phoneNumber: string;
    queryTaxId: string;
    terms: boolean;
    dateOfBirth: string;
    gender: string;
    deleted: boolean;
    deletedDate?: Date;
    notificationToken: NotificationToken | undefined
    typesenseKey: string;
}


export class UserFS implements IUserFS {
    creationTimestamp: string;
    fullName: string;
    id: string;
    orgId: string;
    orgIds: string[];
    phoneNumber: string;
    queryTaxId: string;
    terms: boolean;
    dateOfBirth: string;
    gender: string;
    deleted: boolean;
    deletedDate?: Date | undefined;
    notificationToken: NotificationToken | undefined;
    typesenseKey: string;
    registrationStatus: string | undefined

    constructor(obj?: any) {
        this.creationTimestamp = obj && obj.creationTimestamp || "";
        this.fullName = obj && obj.fullName || "";
        this.id = obj && obj.id || "";
        this.orgId = obj && obj.orgId || "";
        this.orgIds = obj && obj.orgIds || [];
        this.phoneNumber = obj && obj.phoneNumber || "";
        this.queryTaxId = obj && obj.queryTaxId || "";
        this.terms = obj && obj.terms || false;
        this.dateOfBirth = (obj && obj.dateOfBirth) || '';
        this.gender = (obj && obj.gender) || '';
        this.deleted = (obj && obj.deleted) || false;
        this.deletedDate = (obj && obj.deletedDate) || "";
        this.notificationToken = (obj && obj.notificationToken) || "" //&& new NotificationToken(obj.notificationToken) || new 
        this.typesenseKey = obj && obj.typesenseKey || "";
        this.registrationStatus = obj && obj.registrationStatus || "";
    }

    toFireStore() {
        return {
            creationTimestamp: this.creationTimestamp,
            fullName: this.fullName,
            id: this.id,
            orgId: this.orgId,
            orgIds: this.orgIds,
            phoneNumber: this.phoneNumber,
            queryTaxId: this.queryTaxId,
            dateOfBirth: this.dateOfBirth,
            gender: this.gender,
            terms: this.terms,
            deleted: this.deleted,
            deletedDate: this.deletedDate,
            notificationToken: this.notificationToken,
            typesenseKey: this.typesenseKey,
            registrationStatus: this.registrationStatus
        };
    }
}

// collection organzations/:orgId/users
export interface IOrgUser {
    catalogId: string;
    fullName: string;
    id: string;
    isActive: boolean;
    orgId: string;
    phoneNumber: string;
    roles: string[];
    salesRepRefCode: string[];
    warehouses: string[];
    typesenseKey: string;
    isSuperAdmin: boolean;
}

export class OrgUser implements IOrgUser {
    catalogId: string;
    externalReferenceCode: string;
    fullName: string;
    id: string;
    isActive: boolean;
    orgId: string;
    phoneNumber: string;
    roles: string[];
    salesRepRefCode: string[];
    warehouses: string[];
    typesenseKey: string;
    isSuperAdmin: boolean;
    locations: string[];

    constructor(obj?: any) {
        this.catalogId = obj && obj.catalogId || "";
        this.externalReferenceCode = obj && obj.externalReferenceCode || "";
        this.fullName = obj && obj.fullName || "";
        this.id = obj && obj.id || "";
        this.isActive = obj && obj.isActive || false;
        this.orgId = obj && obj.orgId || "";
        this.phoneNumber = obj && obj.phoneNumber || "";
        this.roles = obj && obj.roles || [];
        this.salesRepRefCode = obj && obj.salesRepRefCode || [];
        this.warehouses = obj && obj.warehouses || [];
        this.typesenseKey = obj && obj.typesenseKey || "";
        this.isSuperAdmin = Boolean(obj?.isSuperAdmin)
        this.locations = obj && obj.locations || [];
    }

    toFirestore() {
        return {
            catalogId: this.catalogId,
            externalReferenceCode: this.externalReferenceCode,
            fullName: this.fullName,
            isActive: this.isActive,
            id: this.id,
            orgId: this.orgId,
            phoneNumber: this.phoneNumber,
            roles: this.roles,
            salesRepRefCode: this.salesRepRefCode,
            warehouses: this.warehouses,
            typesenseKey: this.typesenseKey,
            isSuperAdmin: this.isSuperAdmin
        }
    }

}