import { Component, HostListener, OnInit } from '@angular/core';
import { MessagingService } from './core/services/messaging.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import posthog from 'posthog-js';
import { UserService } from './auth/services/user.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  constructor(private swUpdate: SwUpdate, private userService: UserService) { }

  title = 'mProcesses - Your Business in Your Pocket';

  private touchStartY: number = 0;

  ngOnDestroy() {
    // window.removeEventListener('touchmove', this.onWindowTouchMove);
    // window.removeEventListener('touchstart', this.onWindowTouchStart);
    // window.removeEventListener('touchmove', this.onWindowTouchMove);
  }

  ngOnInit() {

    // window.addEventListener('touchmove', this.onWindowTouchMove, { passive: false });
    // window.addEventListener('touchstart', this.onWindowTouchStart, { passive: false });
    // window.addEventListener('touchmove', this.onWindowTouchMove, { passive: false });


    if (this.swUpdate.isEnabled) {

      this.swUpdate.versionUpdates.subscribe(event => {
        console.log("prva verzija:", event)
        // if (confirm("New version available. Load New Version?")) {
        //   window.location.reload();
        // }
      });

      this.swUpdate.versionUpdates.pipe(
        filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
        map(event => ({
          type: event.type,
          currentVersion: event.currentVersion,
          latestVersion: event.latestVersion,
        }))
      ).subscribe(event => {
        console.log(`Current version is ${event.currentVersion.hash}`);
        console.log(`New version available: ${event.latestVersion.hash}`);

        // if (confirm("New version available. Load New Version?")) {
        //   window.location.reload();
        // }
      });
    }
  }


  onWindowTouchStart = (event: TouchEvent): void => {
    this.touchStartY = event.touches[0].screenY;
  }

  onWindowTouchMove = (event: TouchEvent): void => {
    const touch = event.touches[0];
    const isSwipeDown = touch.screenY > this.touchStartY;

    // Check if the swipe is at the top of the page
    if (isSwipeDown && window.scrollY === 0) {
      event.preventDefault();
    }
  }

  //block pull-to-refresh
  // @HostListener('window:touchmove', ['$event'])
  // onWindowTouchMove(event: TouchEvent): void {

  //   // Check the first touch point
  //   if (event.touches.length === 1) {
  //     const touch = event.touches[0];

  //     // Prevent default behavior if the touch is a swipe down
  //     if (touch.screenY > 0) {
  //       event.preventDefault();
  //     }
  //   }
  // }

}
