import { Component } from '@angular/core';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { CountryCodeService } from 'src/app/core/services/country-code.service';
import { FormControl, FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators as Validators } from 'src/app/core/validators/custom.validators';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { AuthService } from '../../services/auth.service';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-phone-login-and-verification',
  templateUrl: './phone-login-and-verification.component.html',
  styleUrls: ['./phone-login-and-verification.component.sass']
})
export class PhoneLoginAndVerificationComponent {

  windowRef: any;
  showProgress: boolean = false;
  phoneSubmitted = false;


  phoneNumber = this.fb.control('', [Validators.required, Validators.minLength(6)]);
  verificationCode = this.fb.control('', [Validators.required])


  loginForm = this.fb.group({
    phoneNumber: this.phoneNumber,
    verificationCode: this.verificationCode
  });

  selectedCountryCode: CountryCode;


  constructor(
    public dialog: MatDialog,
    private countryCodeService: CountryCodeService,
    private authService: AuthService,
    private fb: FormBuilder) {

    this.windowRef = window
    this.showProgress = false

    this.selectedCountryCode = this.countryCodeService.getCodeFromLocalStorage().countryCode as CountryCode;
  }


  countryCodeSelected(code: CountryCode): void {
    this.selectedCountryCode = code;
  }

  ngAfterViewInit() {
    const auth = getAuth();
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("reCAPTCHA solved, allow signInWithPhoneNumber.:", response);
        this.showProgress = false;
      }
    });
  }


  disableSubmitButton(): Boolean {
    return false
  }

  onSubmit() {
    const dr = this.loginForm.get('verificationCode')?.dirty

    if (!this.phoneSubmitted) {
      // Handle phone number submission      
      this.preparePhoneNumberAndSendCode()
    } else {
      // Handle verification code submission      
      this.verifyAuthCode()
    }
  }

  async verifyAuthCode() {
    if (this.loginForm.valid) {
      this.loginForm.disable();
      this.showProgress = true;
      const verificationCode = this.loginForm.get('verificationCode')!.value ?? "0";

      try {
        console.log("sada ce da uradi login sa verificationCode:", verificationCode)
        await this.authService.verifyLoginCode(verificationCode)

      } catch (error: any) {
        this.dialog.open(InfoDialogComponent, {
          width: '360px',
          data: { text: error.message }
        });
        this.loginForm.enable();
        this.showProgress = false;
      }
    }
  }

  async preparePhoneNumberAndSendCode() {
    if (this.loginForm.get('phoneNumber')?.valid) {

      this.showProgress = true;
      this.loginForm.get('phoneNumber')?.disable()

      const appVerifier = this.windowRef.recaptchaVerifier;
      const phoneInput = this.loginForm.get('phoneNumber')!.value ?? ""
      const phone = parsePhoneNumberFromString(phoneInput, this.selectedCountryCode);

      await this.authService.sendLoginCode(phone!.number.toString(), appVerifier)
        .then(result => {
          console.log("dobio info:", result)
          this.phoneSubmitted = true;
        })
        .catch(error => {
          this.dialog.open(InfoDialogComponent, {
            width: '360px',
            data: { text: error.message }
          });
          this.loginForm.get('phoneNumber')?.enable()
          this.showProgress = true;
        })
    }
  }

  resendCode() {
    // this.form.disable();
    // this.showProgress = true;
    // const appVerifier = this.windowRef.signinAppVerifier;

    // from(this.authService.sendLoginCode(this.phoneNumber, appVerifier))
    //   .subscribe(() => {
    //     this.form.enable();
    //     this.showProgress = false;
    //     this.form.reset();
    //     // this.codeField.nativeElement.focus();

    //   }, error => {
    //     this.dialog.open(InfoDialogComponent, {
    //       width: '360px',
    //       data: { text: error.message }
    //     });
    //     this.form.enable();
    //     this.showProgress = false;
    //   })
  }
}
