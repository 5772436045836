import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderSort'
})
export class OrderSortPipe implements PipeTransform {
//'requestedDeliveryDate', 'lastModifiedAt', 'customer.name', 'createdAt'
  transform(value: string): any {
    switch (value) {
      case "requestedDeliveryDate":
        return "Traženi datum isporuke"
      case "lastModifiedAt":
        return "Datum poslednje izmene"
      case "createdAt":
        return "Datum kreiranja"
      case "customer.name":
        return "Naziv kupca"
    }
  }

}
