export interface IInboundOrdersSupplierSalesRep {
  id: String;
  name: String;
  externalReferenceCode: String;
  // salesRepRefCode: Array<String>;
}

export class InboundOrderSupplierSalesRep {
  id: String;
  name: String;
  externalReferenceCode: String;
  // salesRepRefCode: Array<String>;  

  constructor(obj?: any) {
    this.id = obj && obj.id || "";
    this.name = obj && obj.name || "";
    // this.salesRepRefCode = obj && obj.salesRepRefCode || [];
    this.externalReferenceCode = obj && obj.externalReferenceCode || "";
  }

  toFirestore(): any {
    return {
      id: this.id,
      name: this.name,
      externalReferenceCode: this.externalReferenceCode
    }
  }

  fromFirestore(snapshot: any, options: any): InboundOrderSupplierSalesRep {
    const data = snapshot.data(options)
    return new InboundOrderSupplierSalesRep(data);
  }
}
