export enum StatusCode {
    INITIALIZED = "INITIALIZED",
    SUBMITTED = "SUBMITTED",
    CANCELLED = "CANCELLED",
    APPROVED = "APPROVED",
    SENT_TO_SUPPLIER = "SENT_TO_SUPPLIER",
    RECEIVED = "RECEIVED",
    NOT_DELIVERED = "NOT_DELIVERED",
    PENDING = "PENDING",
    DRAFT = "DRAFT",
    MODIFIED_BY_CUSTOMER = "MODIFIED_BY_CUSTOMER",
    MODIFIED_BY_SUPPLIER = "MODIFIED_BY_SUPPLIER",
    REJECTED = "REJECTED",
    ACCEPTED = "ACCEPTED",
    IN_PREPARATION = "IN_PREPARATION",
    PREPARED = "PREPARED",
    INVOICED = "INVOICED",
    SHIPPED = "SHIPPED",
    RECEIVED_BY_CUSTOMER = "RECEIVED_BY_CUSTOMER"
}

