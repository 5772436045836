<mat-form-field class="country-code-selector-container">
  <mat-label>Država</mat-label>
  <mat-select [formControl]="codeFormControl" (selectionChange)="selectCode()" [compareWith]="compareFunction"
    [disabled]="disableControl">
    <mat-select-trigger>
      <span>
        <img src="assets/flags/{{codeFormControl.value.countryCode.toLowerCase()}}.svg" class="country-flag-icon">
        {{ codeFormControl.value.countryCode}}
        <span *ngIf="displayCallingCode"> - {{codeFormControl.value.countryCallingCode}}</span>
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let code of codes" [value]="code">
      <span>
        <img src="assets/flags/{{code.countryCode.toLowerCase()}}.svg" class="country-flag-icon">
        {{ code.countryCode}}
        <span *ngIf="displayCallingCode"> - {{code.countryCallingCode}}</span>
      </span>
    </mat-option>
  </mat-select>
</mat-form-field>