import { last } from "rxjs/operators";

export interface IDocumentMetadata {
  id: string;
  createdAt: Date;
  createdByUserId: string;
  createdByUserName: string;
  lastModifiedAt: Date;
  lastModifiedByUserId: string;
  lastModifiedByUserName: string;
  version: number;
}

export class DocumentMetadata {
  id: string;
  createdAt: Date;
  createdByUserId: string;
  createdByUserName: string;
  lastModifiedAt: Date;
  lastModifiedByUserId: string;
  lastModifiedByUserName: string;
  version: number = 0;

  constructor(obj?: any) {
    this.id = obj && obj.id || "",
      this.createdAt = obj && obj.createdAt && (typeof obj.createdAt.toDate == 'function' &&
        obj.createdAt.toDate() || obj.createdAt) || null;
    this.createdByUserId = obj && obj.createdByUserId || "";
    this.createdByUserName = obj && obj.createdByUserName || "";
    this.lastModifiedAt = obj && obj.lastModifiedAt && (typeof obj.lastModifiedAt.toDate == 'function' &&
      obj.lastModifiedAt.toDate() || obj.lastModifiedAt) || null;
    this.lastModifiedByUserId = obj && obj.lastModifiedByUserId || "";
    this.lastModifiedByUserName = obj && obj.lastModifiedByUserName || "";
    this.version = obj && obj.version || 0;
  }

  toFirestore() {
    return {
      id: this.id,
      createdAt: this.createdAt,
      createdByUserId: this.createdByUserId,
      createdByUserName: this.createdByUserName,
      lastModifiedAt: this.lastModifiedAt,
      lastModifiedByUserId: this.lastModifiedByUserId,
      lastModifiedByUserName: this.lastModifiedByUserName,
      version: this.version
    }
  }
}