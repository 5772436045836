import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { Organization } from '../models/organization/organization';
import { NO_ORG_ID, OrganizationService } from '../services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationResolver implements Resolve<Organization> {

  constructor(private organizationService: OrganizationService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organization> {
    return this.organizationService.orgAsObservable().pipe(
      skipWhile((org: Organization) => {
      if (org.id == NO_ORG_ID) return true;
      if (!org.id) return true;
      return false;
    }),take(1), map((org: Organization ) => {
      console.log('Org resolved')
      return org;
    }))

  }
}
