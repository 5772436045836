import { ERPIntegrationStatus } from "../integration/erpIntegrationStatus"
import { OrderCustomer } from "./customer/orderCustomer"
import { OrderArticle } from "./orderArticle"
import { OrderStatus } from "./orderStatus"
import { Note } from "./note"
import { InboundOrderType } from "./inboundOrderType"
import { IOrderSupplier, OrderSupplier } from "./supplier/orderSupplier"

export interface IOutboundOrder {
    createdAt: Date;
    createdByUserId: string;
    customer: OrderCustomer;
    deliverAsSoonAsPossible: boolean;
    ERPIntegrationStatus: ERPIntegrationStatus;
    ERPOrderReferenceCode: string;
    id: string;
    isMarkedAsArchived: boolean;
    labels: Array<string>;
    lastModifiedAt: Date;
    lastModifiedByUserId: string;
    lastModifiedByUserName: string;
    notes: Array<Note>;
    number: string;
    articles: Array<OrderArticle>;
    status: OrderStatus;
    requestedDeliveryDate: Date;
    supplier: IOrderSupplier;
    // unregisteredSupplier: IOutboundOrderSupplier;
    version: number;
    type: InboundOrderType;
}

export class OutboundOrder implements IOutboundOrder {
    createdAt: Date;
    createdByUserId: string;
    createdByUserName: string;
    customer: OrderCustomer;
    deliverAsSoonAsPossible: boolean;
    ERPIntegrationStatus: ERPIntegrationStatus;
    ERPOrderReferenceCode: string;
    id: string;
    isMarkedAsArchived: boolean;
    labels: Array<string>;
    lastModifiedAt: any;
    lastModifiedByUserId: string;
    lastModifiedByUserName: string;
    notes: Array<Note>;
    number: string;
    articles: Array<OrderArticle>;
    status: OrderStatus;
    requestedDeliveryDate: Date;
    supplier: OrderSupplier;
    // unregisteredSupplier: OutboundOrderSupplier;
    type: InboundOrderType;
    version: number;
    isSplitEnabled: boolean;
    splitAttribute: string;
    splitValues: string[];

    constructor(obj?: any) {
        this.createdAt = obj && obj.createdAt || new Date()
        this.createdByUserId = obj && obj.createdByUserId || "";
        this.customer = obj && new OrderCustomer(obj.customer) || new OrderCustomer();
        this.deliverAsSoonAsPossible = obj && obj.deliverAsSoonAsPossible || false;
        this.ERPIntegrationStatus = obj && obj.ERPIntegrationStatus || new ERPIntegrationStatus();
        this.ERPOrderReferenceCode = obj && obj.ERPOrderReferenceCode || "";
        this.id = obj && obj.id || "";
        this.isMarkedAsArchived = obj && obj.isMarkedAsArchived || false;
        this.labels = obj && obj.labels || [""];
        this.lastModifiedByUserId = obj && obj.lastModifiedByUserId || "";
        this.lastModifiedByUserName = obj && obj.lastModifiedByUserName || ""
        this.lastModifiedAt = obj && obj.lastModifiedAt && (typeof obj.lastModifiedAt.toDate == 'function' &&
            obj.lastModifiedAt.toDate() || obj.lastModifiedAt) || null; this.notes = obj && obj.notes && obj.notes.map((x: any) => new Note(x)) || [];
        this.number = obj && obj.number || "";
        this.articles = obj && obj.articles && obj.articles.map((x: any) => new OrderArticle(x)) || [];
        this.status = obj && new OrderStatus(obj.status) || new OrderStatus();
        this.requestedDeliveryDate = obj && obj.requestedDeliveryDate || null;
        // this.unregisteredSupplier = obj && new OutboundOrderSupplier(obj.supplier) || new OutboundOrderSupplier();
        this.supplier = obj && obj.supplier && new OrderSupplier(obj.supplier) || new OrderSupplier()
        this.type = obj && obj.type || InboundOrderType.ORDER;
        this.version = obj && obj.version || null;
        this.createdByUserName = obj && obj.createdByUserName || "";

        this.isSplitEnabled = obj && obj.isSplitEnabled || false;
        this.splitAttribute = obj && obj.splitAttribute || ""
        this.splitValues = obj && obj.splitValues || [];
    }

    toFirestore(): any {
        return {
            createdAt: this.createdAt,
            createdByUserId: this.createdByUserId,

            customer: this.customer.toFirestore(),
            // customer:this.customer,

            deliverAsSoonAsPossible: this.deliverAsSoonAsPossible,
            lastModifiedByUserId: this.lastModifiedByUserId,
            lastModifiedByUserName: this.lastModifiedByUserName,
            // ERPIntegrationStatus:this.ERPIntegrationStatus.toFirestore(),
            ERPOrderReferenceCode: this.ERPOrderReferenceCode,
            id: this.id,
            isMarkedAsArchived: this.isMarkedAsArchived,
            labels: this.labels,
            lastModifiedAt: this.lastModifiedAt,
            notes: this.notes.map((note: Note) => note.toFirestore()),
            number: this.number,
            articles: this.articles.map((product: OrderArticle) => product.toFirestore()),
            status: this.status.toFirestore(),
            requestedDeliveryDate: this.requestedDeliveryDate,
            // unregisteredSupplier: this.unregisteredSupplier.toFirestore(),
            supplier: this.supplier.toFirestore(),
            version: this.version,
            isSplitEnabled: this.isSplitEnabled,
            splitAttribute: this.splitAttribute,
            splitValues: this.splitValues
        };
    }

    fromFirestore(snapshot: any, options: any): any {
        const data = snapshot.data(options);
        return new OutboundOrder(data);
    }

}