import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'articleNotes'
})
export class ArticleNotesPipe implements PipeTransform {

  transform(value: string): any {
    switch (value) {
      case "":
        return ""
      case "added":
        return "(dodatak)"
      case "cancelled":
        return "(otkazano)"
      case "replaced":
        return "(zamenjeno)"
      case "replacement":
        return "(zamena)"
      case "modified_quantity":
        return '(promenjena količina)'
      case 'out_of_stock':
        return '(nema na lageru)'
      case 'supplement':
        return '(dopuna)'
    }
  }
}