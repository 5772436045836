import { Component, Input } from '@angular/core';
import { Article } from 'src/app/core/models/article/article';
import { ArticleReservations } from 'src/app/core/models/article/articleReservations';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { CountryCurrencyConverterPipe } from 'src/app/shared/pipes/country-currency-converter.pipe';

@Component({
  selector: 'app-shared-article-details',
  templateUrl: './shared-article-details.component.html',
  styleUrls: ['./shared-article-details.component.sass']
})
export class SharedArticleDetailsComponent {
  query: any;
  @Input() article!: Article;
  @Input() reservations!: ArticleReservations | null
  currencyCode: string = '';

  constructor(
    private countryCurrencyConverter: CountryCurrencyConverterPipe,
    private organizationService: OrganizationService
  ) {
    this.currencyCode = this.countryCurrencyConverter.transform(this.organizationService.getOrganization().countryCode)

  }

  public filter(articles: any[]) {
    if (this.query) {
      return articles.filter(c => {
        const nameMatches = c.name.toLowerCase().includes(this.query.toLowerCase())
        const codeMatches = c.code.toLowerCase().includes(this.query.toLowerCase())
        return nameMatches || codeMatches
      })
    }
    return articles
  }

  resetQuery() {
    this.query = ''
  }

}
