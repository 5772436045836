import { FirebaseDate } from "../util/firebaseDate";

export interface ICustomerIntegrationInfo {
    lastSyncDate: Date;
    managedBy: string;
}

export class CustomerIntegrationInfo {

    lastSyncDate: Date;
    managedBy: string;

    constructor(obj?: any) { 
        this.lastSyncDate = obj && obj.lastSyncDate && (typeof obj.lastSyncDate.toDate == 'function' && 
            obj.lastSyncDate.toDate() || obj.lastSyncDate) || null;
        this.managedBy = obj && obj.managedBy || ""
    }

    toFirestore(): any {
        return {
            lastSyncDate: this.lastSyncDate,
            managedBy: this.managedBy
        };
    }

    fromFirestore(snapshot: any, options: any) {
        const data = snapshot.data(options);
        return new CustomerIntegrationInfo(data);
    }
} 