import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { NO_USER, UserService } from "src/app/auth/services/user.service";
import { GlobalActions } from "src/app/core/actions/global.actions";
import { JoinRequest } from "src/app/core/models/organization/joinRequest";
import { Organization } from "src/app/core/models/organization/organization";
import { OrgUser, UserFS } from "src/app/core/models/user/user";
import { UserOrganizationsActions, UserOrganizationsPaylaods } from "./user-organizations.actions";
import { catchError, map, take, tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { stat } from "fs";

export class UserOrganizationsStateModel {
  organizationsBucket: {
    [id: string]: Organization
  } = {};
  joinRequests: JoinRequest[] = [];
  user: UserFS | undefined = NO_USER;
  // selectedOrgId: string = '';
}

@State<UserOrganizationsStateModel>({
  name: 'userOrganizations',
  defaults: {
    organizationsBucket: {},
    joinRequests: [],
    user: NO_USER,
    // selectedOrgId: ''
  }
})
@Injectable()
export class UserOrganizationsState implements NgxsOnInit {

  joinRequestsSub: Subscription | undefined;

  constructor(
    private db: AngularFirestore,
    private store: Store,
    private userService: UserService
  ) {

  }

  ngxsOnInit(ctx: StateContext<UserOrganizationsStateModel>) {
    this.userService.userAsObservable().subscribe((user: UserFS | undefined) => {

      if (this.joinRequestsSub) {
        this.joinRequestsSub.unsubscribe()
      }
      if (!user || user.id == "NO_USER") {
        ctx.setState(patch({
          organizationsBucket: {},
          joinRequests: [],
          user: NO_USER,
          // selectedOrgId: ""
        }))
        return
      }
      ctx.setState(patch({ user: user }))

      //OVO SE NE REINICIJALIZUJE KADA SE PONOVO ULOGUJE 
      //TREBA SREDITI.. RADI SAMO NA HARD REFRESH
      this.joinRequestsSub = this.db.collection('join_requests',
        (ref) => (ref.where('userId', '==', user.id).where('isResolved', '==', false)))
        .valueChanges()
        .subscribe((result: any) => {
          const jrs = result.map((jr: any) => new JoinRequest(jr))
          ctx.setState(patch({ joinRequests: jrs }))
        })
    })
  }


  @Action(UserOrganizationsActions.GetOrganizations)
  fetchOrganizations(ctx: StateContext<UserOrganizationsStateModel>, { }: UserOrganizationsActions.GetOrganizations) {
    let bucket = ctx.getState().organizationsBucket;
    const user = ctx.getState().user;
    if (!user) return;

    user.orgIds.forEach((orgId: string) => {
      if (bucket[orgId]) {
        return
      }
      this.store.dispatch(new UserOrganizationsActions.UpdateOrganizationsBucket(orgId))
    })

  }


  @Action(UserOrganizationsActions.UpdateOrganizationsBucket)
  updateSelectedOrg(ctx: StateContext<UserOrganizationsStateModel>,
    action: UserOrganizationsActions.UpdateOrganizationsBucket) {

    let changes: { [id: string]: Organization } = {}
    return this.db.collection<Organization>('organizations').doc(action.orgId).get()
      .pipe(tap((result) => {

        const organization = result.data()
        if (organization !== undefined) {
          changes[action.orgId] = organization
          ctx.setState(patch({
            organizationsBucket: patch(changes)
          }))
        }

      }))
  }


  // @Action(UserOrganizationsActions.CreateOrganization)
  // async createOrganization(ctx: StateContext<UserOrganizationsStateModel>, { payload }: UserOrganizationsActions.CreateOrganization) {
  //   const user = ctx.getState().user
  //   if (!user) return;
  //   let id = this.db.createId();
  //   let org = new Organization(payload)

  //   org.isRegisteredCustomer = true;
  //   org.isRegisteredSupplier = false;
  //   org.logoUrl = '';
  //   org.registrationDate = new Date();
  //   org.id = id;

  //   org.createdByUserId = user.id

  //   let newUser = new UserFS(user)
  //   newUser.orgIds.push(id)
  //   await Promise.all(
  //     [
  //       this.db.collection('organizations').doc(id).set(org.toFirestore()),
  //       this.db.collection('users').doc(newUser.id).set(newUser.toFireStore()),
  //     ]
  //   )

  //   let _user = await this.userService.getUserByUID(user.id)

  //   let _orgUser = await this.userService.fetchOrgUser(_user.orgId, _user.id)
  //   this.userService.setOrgUser(_orgUser);

  //   //staro
  //   // this.userService.getUserByUID(user.id).subscribe((userFS: UserFS) => {
  //   //   this.userService.setCurrentUser(userFS);
  //   //   this.userService.fetchOrgUser(userFS.orgId, userFS.id).subscribe((orgUser: any) => {
  //   //     this.userService.setOrgUser(orgUser);
  //   //   })
  //   // })

  // }

  // @Action(UserOrganizationsActions.GetJoinRequests)
  // fetchJoinRequests(ctx: StateContext<UserOrganizationsStateModel>, { }: UserOrganizationsActions.GetJoinRequests) {
  //   const user = ctx.getState().user;
  //   if (!user) return;
  //   if (this.joinRequestsSub) this.joinRequestsSub.unsubscribe()
  //   this.joinRequestsSub = this.db
  //     .collection('join_requests', (ref) => (ref.where('userId', '==', user && user.id || "").where('isResolved', '==', false)))
  //     .valueChanges()
  //     .pipe(
  //       map((result: any) => result.map((jr: any) => new JoinRequest(jr)))
  //     ).subscribe((jrs: JoinRequest[]) => {
  //       console.log(jrs)
  //       ctx.setState(patch({
  //         joinRequests: jrs
  //       }))
  //     })
  // }

  // @Action(UserOrganizationsActions.CreateJoinRequest)
  // createJoinRequest(ctx: StateContext<UserOrganizationsStateModel>, { payload }: UserOrganizationsActions.CreateJoinRequest) {
  //   let user = this.userService.getCurrentUser()
  //   if (!user) return
  //   let joinRequest = new JoinRequest(payload)
  //   let id = this.db.createId();
  //   joinRequest.id = id;

  //   return this.db.collection('join_requests').doc(id).set(joinRequest.toFirestore());
  // }

  @Action(UserOrganizationsActions.CancelJoinRequest)
  cancelJoinRequest(ctx: StateContext<UserOrganizationsStateModel>, { payload }: UserOrganizationsActions.CancelJoinRequest) {

    return this.db.collection('join_requests')
      .doc(payload).update({
        resolvedTimestamp: new Date(),
        isApproved: false,
        isResolved: true,
      })
  }


  @Action(UserOrganizationsActions.SwitchOrganization)
  switchOrganization(ctx: StateContext<UserOrganizationsStateModel>, action: UserOrganizationsActions.SwitchOrganization) {
    const curentState = ctx.getState();
    const _newUser = new UserFS(curentState.user)
    _newUser!.orgId = action.organizationId

    ctx.setState({
      ...curentState,
      user: _newUser
    })
  }


  // @Action(GlobalActions.ClearAll)
  // clearAll(ctx: StateContext<UserOrganizationsStateModel>, { }: GlobalActions.ClearAll) {
  //   if (this.joinRequestsSub) this.joinRequestsSub.unsubscribe()
  //   ctx.setState({
  //     organizationsBucket: {},
  //     joinRequests: [],
  //     user: NO_USER
  //   })
  // }



  @Selector()
  static getOrganizations(state: UserOrganizationsStateModel) {
    return Object.values(state.organizationsBucket)
  }

  @Selector()
  static getOrganizationsById(state: UserOrganizationsStateModel) {
    return (orgId: string) => {
      return state.organizationsBucket[orgId]
    }
  }

  @Selector()
  static getJoinRequests(state: UserOrganizationsStateModel) {
    return state.joinRequests
  }

  // @Selector()
  // static getSelectedOrgId(state: UserOrganizationsStateModel) {
  //   return state.selectedOrgId
  // }

}