import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { CountryCodeService } from '../services/country-code.service';

@Injectable({
  providedIn: 'root'
})
export class CountryCodeResolver implements Resolve<boolean> {
  
  constructor(private countryCodeService: CountryCodeService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.countryCodeService.orgResolved.pipe(
      skipWhile((resolved: boolean) => {
      return !resolved
    }),take(1), map((resolved: boolean ) => {
      console.log('country code resolved')
      return true
    }))

  }
}
