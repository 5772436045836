import { Injectable } from "@angular/core";
// import { NgxsFirestoreConnect, StreamEmitted, Emitted } from "@ngxs-labs/firestore-plugin";
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store";
import { append, patch } from "@ngxs/store/operators";
import { take, tap } from "rxjs/operators";
import { UserService } from "src/app/auth/services/user.service";
import { Customer, ICustomer } from "src/app/core/models/customer/customer";
import { OrgUser } from "src/app/core/models/user/user";
import { CustomersActions } from "./customers.action";
// import { CustomersFirestore } from "./customers.firestore";
import { CustomersTypesense, CustomerTS, CustomerTypesenseResponse } from "./customers.typesense";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Vehicle } from "src/app/core/models/vehicle";
import { GlobalActions } from "src/app/core/actions/global.actions";

export class CustomersStateModel {
  searchResult: Array<string> = [];
  totalFound: number = 0;
  totalPages: number = 0;
  outOf: number = 0;
  currentPageLoaded: number = 0;
  customersBucket: { [id: string]: Customer } = {};
  // selectedCustomer:Customer = new Customer();
}

@State<CustomersStateModel>({
  name: 'customersState',
  defaults: {
    searchResult: [],
    totalFound: 0,
    totalPages: 0,
    outOf: 0,
    currentPageLoaded: 0,
    customersBucket: {},
    // selectedCustomer: new Customer()
  }
})
@Injectable()
export class CustomersState implements NgxsOnInit {

  orgId: string = "";

  constructor(
    private userService: UserService,
    // private customersFS: CustomersFirestore,
    // private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private customerTypesense: CustomersTypesense,
    private db: AngularFirestore,
    private store: Store) {
  }

  ngxsOnInit(): void {
    this.userService.orgUserAsObservable().subscribe(
      (user: OrgUser | undefined) => {
        if (!user || !user.id) {
          return;
        }
        this.orgId = user.orgId
      })
  }

  // @Action(CustomersActions.SelectCustomer)
  // SelectCustomer(ctx: StateContext<CustomersStateModel>, 
  //   action: CustomersActions.SelectCustomer) {                
  //     const customerFromBucket = ctx.getState().customersBucket[action.customerId]    
  //     ctx.setState(patch({
  //       selectedCustomer: customerFromBucket
  //     }))      
  // }

  @Action(CustomersActions.UpdateLoadedCustomer)
  updateLoadedCustomer(ctx: StateContext<CustomersStateModel>, action: CustomersActions.UpdateLoadedCustomer) {

    //const state = ctx.getState();

    let newCustomerBucket = { ...ctx.getState().customersBucket };

    newCustomerBucket[action.customerObject.id] = action.customerObject;

    ctx.setState(patch({
      customersBucket: patch(newCustomerBucket)
    }))
  }

  @Action(CustomersActions.LoadCustomerById)
  LoadCustomerById(ctx: StateContext<CustomersStateModel>,
    action: CustomersActions.LoadCustomerById) {
    return this.db.doc<Customer>(`organizations/${this.orgId}/customers/${action.customerId}`)
      .get()
      .pipe(tap(result => {
        const changes: { [id: string]: Customer } = {};
        if (result.data() != undefined) {
          //changes[action.customerId] = result.data()!;        
          let selectedCustomer = new Customer(result.data())
          changes[action.customerId] = selectedCustomer
          ctx.setState(patch({
            customersBucket: patch(changes)
          }))
        }
      }));
  }


  @Action(CustomersActions.LoadCustomers)
  loadCustomers(ctx: StateContext<CustomersStateModel>, { payload }: CustomersActions.LoadCustomers) {
    const bucket = ctx.getState().customersBucket

    const sub = this.customerTypesense.search(payload).pipe(
      tap((customerResponses: CustomerTypesenseResponse) => {
        ctx.setState(patch({
          searchResult: append(customerResponses.results.map((customer: CustomerTS) => customer.id)),
          totalPages: customerResponses.totalPages,
          totalFound: customerResponses.found,
          outOf: customerResponses.out_of,
          currentPageLoaded: customerResponses.currentPage
        }))
      }),
      tap((customers: CustomerTypesenseResponse) => {
        customers.results.forEach((customer: CustomerTS) => {
          if (customer && customer.id && !bucket[customer.id]) {
            this.store.dispatch(new CustomersActions.LoadCustomerById(customer.id))
          }
        })
      }),
      tap(() => {
        sub.unsubscribe()
      }),
    ).subscribe()
  }

  @Action(CustomersActions.Create)
  async createCustomer(ctx: StateContext<CustomersStateModel>, { payload }: CustomersActions.Create) {

    const _newCustomerId = this.db.createId()
    payload.id = _newCustomerId

    console.log(`organizations/${this.orgId}/customers`, payload)

    await this.db.collection(`organizations/${this.orgId}/customers`)
      .doc(_newCustomerId).set(payload)

    this.store.dispatch(new CustomersActions.LoadCustomerById(_newCustomerId))

  }

  @Action(CustomersActions.ResetSearchResult)
  resetSearchResult(ctx: StateContext<CustomersStateModel>) {
    ctx.patchState({ searchResult: [] })
  }

  @Selector()
  static getCustomerTotalPages(state: CustomersStateModel) {
    return state.totalPages
  }

  @Selector()
  static getCustomerNextPage(state: CustomersStateModel) {
    return state.currentPageLoaded + 1
  }

  @Selector()
  static getCustomers(state: CustomersStateModel) {
    return Object.values(state.customersBucket)
  }

  static getCustomerFromStateById(customerId: string) {
    return createSelector([CustomersState], (state: CustomersStateModel) => {
      return state.customersBucket[customerId]
    })
  }




  @Selector()
  static getCustomer(state: CustomersStateModel) {
    return (customerId: string) => {
      return state.customersBucket[customerId]
    }
  }

  @Action(GlobalActions.ClearAll)
  clearState(ctx: StateContext<CustomersStateModel>, { }: GlobalActions.ClearAll) {
    ctx.setState({
      searchResult: [],
      totalFound: 0,
      totalPages: 0,
      outOf: 0,
      currentPageLoaded: 0,
      customersBucket: {},
    })
  }

}
