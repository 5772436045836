import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-download-button',
  templateUrl: './file-download-button.component.html',
  styleUrls: ['./file-download-button.component.sass']
})
export class FileDownloadButtonComponent implements OnInit {

  @Input()
  buttonText: string = ""
  
  @Input()
  fileURL: string = ""

  @Input()
  fileName: string = ""

  constructor() { }

  ngOnInit(): void {
  }

  downloadFile(): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.fileURL);
    link.setAttribute('download', this.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
