<div class="cards-container">
    <mat-accordion>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Pretraga artikala
                </mat-panel-title>
                <mat-panel-description>
                    <div>{{query}}</div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field style="width: 100%;">
                <mat-label>Potraži artikle</mat-label>
                <input matInput placeholder="Unesite naziv" type="text" [(ngModel)]="query"
                    (ngModelChange)="onSearchChange(query)">
            </mat-form-field>


        </mat-expansion-panel>
    </mat-accordion>
</div>

<div *ngIf="articles && articles.length">
    <div class="customer-container">
        <div *ngFor="let article of articles | articleSearchPipe:query">
            <app-shared-article-details [article]="article" [reservations]="reservations"></app-shared-article-details>
        </div>
    </div>
    <!-- <mat-card appearance="outlined" (click)="getNextPage()" >Učitaj jos</mat-card> -->
    <button mat-raised-button color="primary" (click)="getNextPage()" style="margin-bottom: 80px;">Učitaj još</button>
</div>