export interface JoinRequest {
    id: string;
    creationTimestamp: Date;
    fullName: string;
    isApproved: boolean;
    isResolved: boolean;
    orgId: string;
    orgName: string;
    phoneNumber: string;
    resolvedTimestamp: Date;
    userId: string;
}

export class JoinRequest implements JoinRequest {
    id: string;
    creationTimestamp: Date;
    fullName: string;
    isApproved: boolean;
    isResolved: boolean;
    orgId: string;
    orgName: string;
    phoneNumber: string;
    resolvedTimestamp: Date;
    userId: string;

    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.creationTimestamp = obj && obj.creationTimestamp && (typeof obj.creationTimestamp.toDate == 'function' &&
            obj.creationTimestamp.toDate() || obj.creationTimestamp) || new Date();
        this.fullName = obj && obj.fullName || "";
        this.isApproved = obj && obj.isApproved || false;
        this.isResolved = obj && obj.isResolved || false;
        this.orgId = obj && obj.orgId || "";
        this.orgName = obj && obj.orgName || "";
        this.phoneNumber = obj && obj.phoneNumber || "";
        this.resolvedTimestamp = obj && obj.resolvedTimestamp && (typeof obj.resolvedTimestamp.toDate == 'function' &&
            obj.resolvedTimestamp.toDate() || obj.resolvedTimestamp) || new Date();
        this.userId = obj && obj.userId || "";
    }

    toFirestore() {
        return {
            id: this.id,
            creationTimestamp: this.creationTimestamp,
            fullName: this.fullName,
            isApproved: this.isApproved,
            isResolved: this.isResolved,
            orgId: this.orgId,
            orgName: this.orgName,
            phoneNumber: this.phoneNumber,
            resolvedTimestamp: this.resolvedTimestamp,
            userId: this.userId
        }
    }
}