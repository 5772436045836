

export interface INotificationToken {
    timestamp: string;
    token: string;
}

export class NotificationToken implements INotificationToken {
    timestamp: string;
    token: string;

    constructor(timestamp: string, token: string) {
        this.timestamp = timestamp; //obj && obj.timestamp || '';
        this.token = token;//obj && obj.token || '';
    }

    toFirestore(): any {
        return {
            timestamp: this.timestamp,
            token: this.token
        }
    }
}