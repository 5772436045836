import { ArticleReservation } from "./articleReservation";

export interface IArticleReservations {
  articles: ArticleReservation[]
}

export class ArticleReservations implements IArticleReservations {

  articles: Array<ArticleReservation>

  constructor(obj?: any) {
    this.articles = obj && obj.articles && obj.articles.map((articleReservation: any) => new ArticleReservation(articleReservation)) || []
  }

  toFirestore() {
    return {
      article: this.articles.map((article: ArticleReservation) => article.toFirestore())
    }
  }
}
